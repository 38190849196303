import React, {useState} from 'react'
import BannerContainer from './BannerContainer';
import FormFields from '../../../../elements/FormFields';
import OmniDataService from '../../../../../services/omniAxios.js'
import SaveSheet from '../../../../elements/saveSheet';

export default function Design1(props) {
  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle= "Prospecting OHR Only";
  const [formData, setFormData] = useState(false);
  const themeColumnName = 'Color Scheme'

  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));
  const saving = true;

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
  }


  // todo update
  function formatFormData(data) {
    return {
      name: "",
      Headline_Color: data?.supplementary_row?.['Headline Color'] || "",
      CTA_Box_Color: data?.supplementary_row?.['CTA Box Color'] || "",
      CTA_Font_Color: data?.supplementary_row?.['CTA Font Color'] || "",
      Logo_URL: data?.supplementary_row?.['Logo URL'] || "",
      CTA: data?.row?.CTA || "",
      Exit_Url: data?.row?.['Exit Url'] || "",
      Headline: data?.row?.Headline || "" || "",
      SubHeadline: data?.row?.SubHeadline || "",
      Image_Vertical: data?.row?.['Image Vertical'] || "",
      Image_Horizontal: data?.row?.['Image Horizontal'] || "",  // eslint-disable-next-line
      Color_Theme: data?.supplementary_row?.['Color Scheme'] || "",
      sheet: data?.sheet,
      design: sheetTitle
    }
  }

  // todo update
  const handleColorThemeSubmit = (e) => {
    for (let theme of formData.sheet) {
      let cell = theme[themeColumnName]
      if (formData.Color_Theme === cell) {
        setFormData({
          name: "",
          Headline_Color: theme['Headline Color'],
          CTA_Box_Color: theme['CTA Box Color'],
          CTA_Font_Color: theme['CTA Font Color'],
          Logo_URL: theme['Logo URL'],
          Exit_Url: formData.Exit_Url,
          CTA: formData.CTA,
          Headline: formData.Headline,
          SubHeadline: formData.SubHeadline,
          Image_Vertical: formData.Image_Vertical,
          Image_Horizontal: formData.Image_Horizontal,
          Color_Theme: theme['Color Scheme'],
          sheet: formData.sheet,
          design: sheetTitle
        })
      }
    }
    refreshAll();
  }


  const getSheet = async (e) => {
    return await OmniDataService.getDesignProspectingQ42024Sheets();
  }

  const note = "*You cannot save a new color theme here. You must save a new hotel code or change the hotel code / color mapping in the feed sheet."


  return (
          <div>
            <FormFields themeColumnName={themeColumnName} handleColorThemeSubmit={handleColorThemeSubmit} refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} note={note}/>
            {bannerActive &&
                <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
            {formData && bannerActive &&
              <BannerContainer setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
            }
          </div>
  );
}

