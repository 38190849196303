import { IFrame } from './IFrame';

export default function _300x250(props){

    return (
        <>
            <IFrame {...props} iframenumber={0} width={"300"} height={"250"} title={"_300x250-Banner"} id={"_300x250-Banner"} src={'/Generic_Banners/generic_banner/Design1/Studio_Version/generic_responsive/index.html'}/>
        </>
    );
}
