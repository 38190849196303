import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
                           children,
                           ...props
                       }) => {
    var iframe = document.getElementById(props.title);

    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');
        script.append(`
        Enabler.setProfileId(10838887);
        var devDynamicContent = {};

    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023 = [{}];
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0]._id = 0;
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].Unique_ID = 1;
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].IsApproved = true;
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].IsDefault = false;
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].Placement_ID = "";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].StartDate = {};
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].StartDate.RawValue = "03/01/2023";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].StartDate.UtcValue = 1677657600000;
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].EndDate = {};
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].EndDate.RawValue = "03/01/2024";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].EndDate.UtcValue = 1709280000000;
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].exit_url = "${props.exit_url}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].headline_1 = "${props.headline_1}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].headline_2 = "${props.headline_2}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].sale_present = ${props.sale_present};
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].sale_duration = "${props.sale_duration}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].legal_statement_present = ${props.legal_statement_present};
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].legal_statement = "${props.legal_statement}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].sticker_present = ${props.sticker_present};
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].sticker_statement = "${props.sticker_statement}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].cta = "${props.cta}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].Color_Theme = "${props.Color_Theme}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].logo = "${props.logo}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].image_vert = "${props.image_vert}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].image_horiz = "${props.image_horiz}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Q2_2023[0].reporting_label = "example0";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023 = [{}];
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0]._id = 0;
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].Unique_ID = 1;
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].primary_color = "${props.primary_color}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].headline1_color = "${props.headline_1_color}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].headline2_color = "${props.headline_2_color}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].sale_duration_color = "${props.sale_duration_color}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].legal_statement_color = "${props.legal_statement_color}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].sticker_add_on_color = "${props.sticker_add_on_color}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].sticker_container_color = "${props.sticker_container_color}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].cta_color = "${props.cta_color}";
    devDynamicContent.Nothing_Bundt_Cakes_Banner_Options_Feed_Color_Themes_Q2_2023[0].cta_button_color = "${props.cta_button_color}";
    Enabler.setDevDynamicContent(devDynamicContent);`);

        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            Array.from(document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.head.querySelectorAll('script')).pop().append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
