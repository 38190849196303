import  { keyframes }  from  'styled-components';

export const PackageImageFade = keyframes`
    0%   { opacity: 0; }
    10%  { opacity: 1; }
    100% { opacity: 1; transform: scale(1.05);}
`

export const PackageHeadline1 = keyframes`
    0%  { opacity: 0; }
    10% { opacity: 0; }
    40% { opacity: 1; }
    70% { opacity: 1; }
    90% { opacity: 0; }
`

export const PackageHeadline2 = keyframes`
    0%   { opacity: 0; }
    75%  { opacity: 0; }
    100% { opacity: 1; }
`

export const PackageButtonFade = keyframes`
    0%  { opacity: 0; }
    20% { opacity: 0; }
    30% { opacity: 1; transform: scale(1); }
    70% { transform: scale(1); }
    80% { transform: scale(1.07); }
    90% { transform: scale(1); }
`

export const PackageShine = keyframes`
    0%   { opacity: 0; }
    60%  { opacity: 0; }
    75%  { opacity: 1; transform: skewX(-20deg) translateX(0); }
    90%  { transform: skewX(-20deg) translateX(130px); }
    100% { transform: skewX(-20deg) translateX(100px); opacity: 0; }
`

export const BarFade = keyframes`
    0%   { opacity: 0; }
    100% { opacity: 1; }
`

export const MobilePackageHeadline2 = keyframes`
    0%   { opacity: 0; }
    45%  { opacity: 0; }
    65%  { opacity: 1; }
    95%  { opacity: 1; }
    100% { opacity: 0; }
`

export const MobilePackageButtonFade = keyframes`
    0%  { opacity: 0; }
    55% { opacity: 0; }
    60% { opacity: 1; transform: scale(1); }
    65% { transform: scale(1); }
    68% { transform: scale(1.07); }
    71% { transform: scale(1); }
`

export const MobilePackageShine = keyframes`
    0%  { opacity: 0; }
    60% { opacity: 0; }
    65% { opacity: 1; transform: skewX(-20deg) translateX(0); }
    68% { transform: skewX(-20deg) translateX(130px); }
    72% { transform: skewX(-20deg) translateX(100px); opacity: 0; }
`