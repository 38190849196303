import { IFrame } from './IFrame';

export default function _300x50(props) {

    return (
        <>
            <IFrame {...props} iframenumber={5} width={"300"} height={"50"} title={"_300x50-Banner"} id={"_300x50-Banner"} src={'/Generic_Banners/generic_banner/Design1/Studio_Version/generic_responsive/index.html'}/>
        </>
    );
    }

