import { IFrame } from './IFrame';

export default function _728x90(props){

    return (
        <>
            <IFrame {...props} iframenumber={1} width={"728"} height={"90"} title={"_728x90-Banner"} id={"_728x90-Banner"} src={'/SurveyMonkey-Momentive/survey-monkey-momentive/smDesign9/Studio_Version/smdesign9_responsive/smdesign9_responsive_728x90/index.html'}/>
        </>
    );
}
