import { IFrame } from './IFrame';

export default function _300x600(props){

  return (
      <>
        <IFrame {...props} iframenumber={4} width={"300"} height={"600"} title={"_300x600-Banner"} id={"_300x600-Banner"} src={'/TheContainerStore/the-container-store/2022_Design/Studio_Version/TCS_Banner_Updates_Responsive/index.html'}/>
      </>
  );
}
