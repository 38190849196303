import http from "../http-common";

class OmniDataService {
    getAllSheets() {
        return http.get();
    }

    getDesign8Sheets() {
        return http.get("/design8Sheets");
    }

    getDesign1Sheets() {
        return http.get("/design1Sheets");
    }

    getDesign4Sheets() {
        return http.get("/design4Sheets");
    }

    getDesignProspectingSheets() {
        return http.get("/designProspectingSheets");
    }

    getDesignProspectingQ42024Sheets() {
        return http.get("/designProspectingQ42024Sheets");
    }

    getDesignRetargetingSheets() {
        return http.get("/designRetargetingSheets");
    }

    getDesignNothingBundtCakesQ22023Sheets() {
        return http.get("/designNothingBundtCakesQ22023Sheets");
    }

    getDesignAcmeSheets() {
        return http.get("/designAcmeDesignSheets");
    }

    getDesignMomentive1Sheets() {
        return http.get("/designMomentive1Sheets");

    }
    getDesignSMDesign8Sheets() {
        return http.get("/designSMDesign8Sheets");

    }

    getDesignSMDesign9Sheets() {
        return http.get("/designSMDesign9Sheets");

    }

    getDesignTCS2022Sheets() {
        return http.get("/designTCS2022Sheets");

    }
    getDesignGeneric_BannersSheets() {
        return http.get("/designGeneric-Bannerssheets");
    }

    getDesignTestSheets() {
        return http.get("/designTestDesignSheets");
    }

    getOneSheet(id) {
        return http.get(`/sheets/${id}`)
    }

    createSheet(data) {
        return http.post("/preview", data)
    }

    deleteSheet(data) {
        return http.delete("/preview", {data : {_id: data}})
    }
}

export default new OmniDataService();
