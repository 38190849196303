import React from 'react'
import { AppBar, Container, Toolbar, Typography } from '@mui/material/'
import { Link } from 'react-router-dom'
import logo from '../../images/PMG_Logo_RGB_FullColor_White_170_RLSD (1).png'

const pages = [{title: "Saved Previews", route: "/sheets"}]

export default function NavBar(props) {
    return(
        <AppBar position="static" sx={{backgroundColor:"#2f2f2f", display:"flex", justifyContent:"space-between"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters >
                    <Link to="/" >
                        <img style={{width:"15%"}} src={logo} alt="logo"/>
                    </Link>
                    {pages.map((page) => (
                        <Link key={Math.random().toString(16).slice(2)} to={page.route} style={{textDecoration:"none"}}>
                            <Typography sx={{color:"white", fontSize:"20px", mr:10}}>{page.title}</Typography>
                        </Link>
                    ))}
                    <Typography sx={{display:"flex", justifyContent:"flex-end"}}>{props.email}</Typography>
                    <form action="/logout" method="POST">
                        <button type="submit">logout</button>
                    </form>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
