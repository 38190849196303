import React, { useState } from 'react'
import BannerContainer from './BannerContainer'
import OmniDataService from '../../../../../services/omniAxios.js'
import FormFields from "../../../../elements/FormFields";
import SaveSheet from "../../../../elements/saveSheet";

export default function Design1(props) {
  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
  }
  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle = "Momentive 2022";
  const [formData, setFormData] = useState(false);
  const saving = false;

  function formatFormData(data) {
    return {
      name: "",
      cta: data?.row?.CTA,
      headline: data?.row?.Headline.replaceAll('"', '\\"'),
      subheadline: data?.row?.Subheadline.replaceAll('"', '\\"'),
      primary_color: data?.row?.Primary_Color,
      secondary_color: data?.row?.Secondary_Color,
      cta_button_color: data?.row?.CTA_Button_Color,
      cta_text_color: data?.row?.CTA_Text_Color,
      logotype_vert: data?.row?.LogoType_Vert,
      logotype_horiz: data?.row?.LogoType_Horiz,
      image_horiz: data?.row?.image_horiz,
      image_vert: data?.row?.image_vert,
      texture_url: data?.row?.texture_url,
      column_color: data?.row?.column_color,
      logo_mobile_horiz: data?.row?.logo_mobile_horiz,
      logo_mobile_vert: data?.row?.logo_mobile_vert,
      reporting_label: data?.row?.Reporting_Label|| "",
      location: data?.row?.Location || "",
      design: sheetTitle
    }
  }

  const getSheet = async (e) => {
    return await OmniDataService.getDesignMomentive1Sheets();
  }

  return (
      <div>
        <FormFields refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} saving={saving}/>
        {bannerActive &&
            <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
        {formData && bannerActive &&
            <BannerContainer setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
        }
      </div>
  );
}
