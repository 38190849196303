import {Button, Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import OmniDataService from "../../services/omniAxios";
import { useLocation } from "react-router-dom";

export default function SaveSheet(props) {
    const [sheetName, setSheetName] = useState("");
    const [isSheetNameEmpty, setIsSheetNameEmpty] = useState(false);
    const [isSheetNameDuplicate, setIsSheetNameDuplicate] = useState(false);
    const [savedToSheet, setSavedToSheet] = useState(false);
    const [sheetID, setSheetID] = useState('');
    const location = useLocation();
    const [copied, setCopied] = useState(false);

    async function findDuplicate(sheetName) {
        let arr = await fetchSheets();
        arr.push('');
        const res = arr.find(element => element === sheetName);
        if (res === '') {
            return '';
        }
        return res !== undefined;
    }

    const saveSheet = (e) => {
        e.preventDefault();
        findDuplicate(sheetName).then((parent) => {
            if (parent === true) {
                setIsSheetNameDuplicate(true);
                throw new Error("duplicate sheet name");
            } else if (parent === '') {
                setIsSheetNameEmpty(true);
                throw new Error("sheet name is empty!");
            }
            props.formData.name = sheetName;
            OmniDataService.createSheet(props.formData).then(res => {
                console.log(res)
                setSheetID(res.data.sheet_id)
            })
                .catch(e => {
                    console.log(e);
                }).then(() => {
                    // Enable the 'Copy Sheet' button if the sheet has been saved
                    setSavedToSheet(true);
                })
        }).catch(error => {
            console.log(error);
        })
    }

    async function fetchSheets() {
        let res = [];
        const arr = await props.getSheet()
        arr["data"].forEach(element => res.push(element["name"]));
        return res;
    }

    const copySheet = () => {
        /**
         * Copy the sheet to the user's clipboard
         */
        let dest = window.location.origin + '/design' + location.pathname.slice(1) + 'sheets/' + sheetID;
        navigator.clipboard.writeText(dest);
        setCopied(true);
    }


    return (
      <div>
        <form onSubmit={saveSheet}>
          <Stack justifyContent="center" direction="row" spacing={4}>
            <TextField
              id="standard-basic"
              variant="standard"
              placeholder="Sheet Name"
              type="text"
              error={isSheetNameDuplicate || isSheetNameEmpty}
              helperText={
                (isSheetNameEmpty && "Please specify a sheet name.") ||
                (isSheetNameDuplicate && "Sheet name already exists. Please enter a unique name.")
              }
              onChange={(e) =>
                setIsSheetNameEmpty(e.target.value === "") ||
                setSheetName(e.target.value) ||
                setIsSheetNameDuplicate(false) ||
                setSavedToSheet(false) ||
                setCopied(false)
              }
            />
            <Button
              sx={{ backgroundColor: "#14A7B6" }}
              variant="contained"
              type="submit"
            >
              Save Sheet
            </Button>
            <Button
              sx={{ backgroundColor: "#14A7B6" }}
              variant="contained"
              onClick={copySheet}
              disabled={!savedToSheet}
            >
              {copied ? "Copied!" : "Copy Link"}
            </Button>
          </Stack>
        </form>
      </div>
    );
}
