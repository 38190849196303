import { IFrame } from './IFrame';

export default function _728x90(props){

    return (
        <>
            <IFrame {...props} iframenumber={1} width={"728"} height={"90"} title={"_728x90-Banner"} id={"_728x90-Banner"} src={'/TheContainerStore/the-container-store/2022_Design/Studio_Version/TCS_Banner_Updates_Responsive/index.html'}/>

        </>
    );
}
