import { IFrame } from './IFrame';

export default function _728x90(props){

    return (
        <>
            <IFrame {...props} iframenumber={3} width={"970"} height={"250"} title={"_970x250-Banner"} id={"_970x250-Banner"} src={'/SurveyMonkey-Momentive/survey-monkey-momentive/smDesign9/Studio_Version/smdesign9_responsive/smdesign9_responsive_970x250/index.html'}/>
        </>
    );
}
