import { AdContainer, LeftBottomBar, LeftTopBar, Logo, LogoContainer, Headline1, Headline2, HeadlineContainer, HeadlineWrapper, Button, ButtonContainer, Shine} from '../styles/styled_300x50'

export default function _300x50(props) {
    return (
        <>
            <AdContainer>
                <LeftTopBar primaryColor={props.primaryColor}></LeftTopBar>
                <LeftBottomBar secondaryColor={props.secondaryColor}></LeftBottomBar>
                <LogoContainer primaryColor={props.primaryColor}>
                    <Logo src={props.logoUrl}></Logo>
                </LogoContainer>
                <HeadlineContainer>
                    <HeadlineWrapper>
                        <Headline1 primaryColor={props.primaryColor}>{props.headline1}</Headline1>
                    </HeadlineWrapper>
                    <HeadlineWrapper>
                        <Headline2 primaryColor={props.primaryColor}>{props.headline2}</Headline2>
                    </HeadlineWrapper>
                </HeadlineContainer>
                <ButtonContainer>
                    <Button primaryColor={props.primaryColor}>{props.CTA}</Button>
                    <Shine></Shine>
                </ButtonContainer>
            </AdContainer>
        </>
    );
}