import { AdContainer, TopBar, LogoContainer, Logo, Image1, ImageContainer, Headline1, Headline2, HeadlineContainer, Button, ButtonContainer, Shine} from '../styles/styled_300x250'

export default function _300x250(props){
    return (
      <>
        <AdContainer>
            <TopBar primaryColor={props.primaryColor}></TopBar>
            <ImageContainer>
                <Image1 image1Horizontal={props.image1Horizontal} ></Image1>
            </ImageContainer>
            <LogoContainer primaryColor={props.primaryColor}>
                <Logo src={props.logoUrl}></Logo>
            </LogoContainer>
            <HeadlineContainer>
                <Headline1 primaryColor={props.primaryColor}>{props.headline1}</Headline1>
                <Headline2 primaryColor={props.primaryColor}>{props.headline2}</Headline2>
            </HeadlineContainer>
            <ButtonContainer>
                <Button primaryColor={props.primaryColor}>{props.CTA}</Button>
                <Shine></Shine>
            </ButtonContainer>
        </AdContainer>
      </>
      );
  }