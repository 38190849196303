import React, {useState, useEffect} from 'react'
import OmniDataService from '../../../../services/omniAxios.js'
import PreviewSheets from "../../PreviewSheets";

const PreviewSheetList = () => {
    const [sheets, setSheets] = useState([])

    useEffect(() => {
        retrieveSheetList();
    }, [])

    const retrieveSheetList = () => {
        /**  Match the Design Name**/
        OmniDataService.getDesignProspectingSheets()
            .then(res => {
               setSheets(
                    res.data.sort(function (a, b) {
                        if (a._timestamp < b._timestamp) {
                            return -1;
                        }
                        if (a._timestamp > b._timestamp) {
                            return 1;
                        }
                        return 0;
                    }))
            })
    }

    const route = "/designProspectingsheets/";

    return (
        <PreviewSheets route={route} sheets={sheets} setSheets={setSheets} retrieveSheetList={retrieveSheetList}/>
    )
}

/** Match to the designName**/
export default PreviewSheetList;
