import React, {useState} from 'react'
import BannerContainer from './BannerContainer';
import FormFields from '../../../../elements/FormFields';
import OmniDataService from '../../../../../services/omniAxios.js'
import SaveSheet from '../../../../elements/saveSheet';

export default function Design1(props) {
  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle= "Prospecting";
  const [formData, setFormData] = useState(false);
  const themeColumnName = 'Color Theme'

  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));
  const saving = true;

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
  }


  function formatFormData(data) {
    return {
      name: "",
      primary_color: data?.supplementary_row?.primary_color || "",
      secondary_color: data?.supplementary_row?.secondary_color || "",
      headline_color: data?.supplementary_row?.headline_color || "",
      cta_text_color: data?.supplementary_row?.cta_text_color || "",
      cta_button_color: data?.supplementary_row?.cta_button_color || "",
      logo_vertical: data?.supplementary_row?.['Logo Vertical'] || "",
      logo_horizontal: data?.supplementary_row?.['Logo Horizontal'] || "",
      logo_mobile: data?.supplementary_row?.['Logo Mobile'] || "",  // eslint-disable-next-line
      exit_url: data?.row?.['Exit URL'] || "",
      headline: data?.row?.Headline || "" || "",
      cta: data?.row?.CTA || "",
      image_1000x250: data?.row?.Image_1000x250 || "",
      image_634x300: data?.row?.Image_634x300 || "",  // eslint-disable-next-line
      color_theme: data?.supplementary_row?.['Color Theme'] || "",
      hotel_codes: data?.row?.HotelCodes || "",
      is_approved: data?.row?.IsApproved || "",
      is_default: data?.row?.IsDefault || "",
      placement_id: data?.row?.Placement_ID || "",
      campaign_budget_source: data?.row?.Campaign_Budget_Source || "",
      campaign_name: data?.row?.CampaignName || "",
      start_date: data?.row?.StartDate || "",
      end_date: data?.row?.EndDate || "",  // eslint-disable-next-line
      reporting_label: data?.row?.["Reporting Label"] || "",
      sheet: data?.sheet,
      design: sheetTitle
    }
  }
  const handleColorThemeSubmit = (e) => {

    for (let theme of formData.sheet) {
      let cell = theme['Color Theme']
      if (formData.color_theme === cell) {
        setFormData({
          name: "",
          primary_color: theme.primary_color,
          secondary_color: theme.secondary_color,
          headline_color: theme.headline_color,
          cta_text_color: theme.cta_text_color,
          cta_button_color: theme.cta_button_color,
          logo_vertical: formData.logo_vertical,
          logo_horizontal: formData.logo_horizontal,
          logo_mobile: formData.logo_mobile,
          exit_url: formData.exit_url,
          headline: formData.headline,
          cta: formData.cta,
          image_1000x250: formData.image_1000x250,
          image_634x300: formData.image_634x300,
          color_theme: formData.color_theme,
          hotel_codes: formData.hotel_codes,
          is_approved: formData.is_approved,
          is_default: formData.is_default,
          placement_id: formData.placement_id,
          campaign_budget_source: formData.campaign_budget_source,
          campaign_name: formData.campaign_name,
          start_date: formData.start_date,
          end_date: formData.end_date,
          reporting_label: formData.reporting_label,
          sheet: formData.sheet,
          design: sheetTitle
        })
      }
    }
    refreshAll();
  }


  const getSheet = async (e) => {
    return await OmniDataService.getDesignProspectingSheets();
  }

  const note = "*You cannot save a new color theme here. You must save a new hotel code or change the hotel code / color mapping in the feed sheet."


  return (
          <div>
            <FormFields themeColumnName={themeColumnName} handleColorThemeSubmit={handleColorThemeSubmit} refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} note={note} saving={saving}/>
            {bannerActive &&
                <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
            {formData && bannerActive &&
              <BannerContainer setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
            }
          </div>
  );
}

