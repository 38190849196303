import { Stack } from "@mui/material/"

import Omni300x600 from './Omni_300x600';
import Omni300x250 from './Omni_300x250'
import Omni300x50 from './Omni_300x50'
import Omni320x50 from './Omni_320x50'
import Omni728x90 from './Omni_728x90'
import Omni970x250 from './Omni_970x250'
import {BannerContainer} from "../../../../styles/styledMiscComponents";
import React from "react";
import {Button} from "@mui/material";

export default function OmniBannerContainer(props) {

    let buttonClicked300x250 = () => {
        props.setkey300x250(props.key300x250+1);
    }
    let buttonClicked728x90 = () => {
        props.setkey728x90(props.key728x90+1);
    }
    let buttonClicked320x50 = () => {
        props.setkey320x50(props.key320x50+1);
    }
    let buttonClicked920x50 = () => {
        props.setkey970x250(props.key970x250+1);
    }
    let buttonClicked300x600 = () => {
        props.setkey300x600(props.key300x600+1);
    }
    let buttonClicked300x50 = () => {
        props.setkey300x50(props.key300x50+1);
    }

    let refreshAll = () => {
        props.setkey300x50(props.key300x50+1);
        props.setkey300x600(props.key300x600+1);
        props.setkey970x250(props.key970x250+1);
        props.setkey320x50(props.key320x50+1);
        props.setkey728x90(props.key728x90+1);
        props.setkey300x250(props.key300x250+1);
    }

    return(
        <div>
            <Stack alignItems="center" direction="column" spacing={2}>
                <Button sx={{backgroundColor: "#14A7B6", width: "20%", alignSelf: "center"}} onClick={refreshAll} variant="contained" >REFRESH ALL</Button>
                <BannerContainer>
                    <Omni300x250 key={props.key300x250} image1Horizontal={props.image1Horizontal} primaryColor={props.primaryColor} CTA={props.cta} headline1={props.headline1} headline2={props.headline2} logoUrl={props.logoUrl}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}} size={"small"} onClick={buttonClicked300x250} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
                    <Omni728x90 key={props.key728x90} image1Vertical={props.image1Vertical} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} CTA={props.cta} headline1={props.headline1} headline2={props.headline2} logoUrl={props.logoUrl}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}} size={"small"}  onClick={buttonClicked728x90} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
                    <Omni320x50 key={props.key320x50}  primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} CTA={props.cta} headline1={props.headline1} headline2={props.headline2} logoUrl={props.logoUrl}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}} size={"small"}  onClick={buttonClicked320x50} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
`                   <Omni970x250  key={props.key970x250}  image1Vertical={props.image1Vertical} primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} CTA={props.cta} headline1={props.headline1} headline2={props.headline2} logoUrl={props.logoUrl}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}}  size={"small"}  onClick={buttonClicked920x50} variant="contained">Refresh</Button>`
                </BannerContainer>
                <BannerContainer>
                    <Omni300x600 key={props.key300x600}  image1Vertical={props.image1Vertical} image2Vertical={props.image2Vertical} primaryColor={props.primaryColor} CTA={props.cta} headline1={props.headline1} headline2={props.headline2} logoUrl={props.logoUrl}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}}  size={"small"}  onClick={buttonClicked300x600} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
                    <Omni300x50 key={props.key300x50}  primaryColor={props.primaryColor} secondaryColor={props.secondaryColor} CTA={props.cta} headline1={props.headline1} headline2={props.headline2} logoUrl={props.logoUrl}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}}  size={"small"}  onClick={buttonClicked300x50} variant="contained">Refresh</Button>
                </BannerContainer>
            </Stack>
        </div>
    )
}
