import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import OmniDataService from '../../../../services/omniAxios.js'
import Banners from '../../../clients/omni/design4/elements/OmniBannerContainer.js'

const PreviewSheet = () => {
    let { id } = useParams();
    const initialSheetState = null;


    const [sheet, setSheet] = useState([initialSheetState])
    const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
    const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
    const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
    const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
    const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
    const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));
    
    const getSheet = id => {
        OmniDataService.getOneSheet(id)
            .then(res => {
                setSheet(res.data)
            })
            .catch(e => {
                console.log(e)
            })
    }
    
    useEffect(() => {
        getSheet(id);
    }, [id])

    return(
        <div>
            <h2>{sheet.name}</h2>
            <Banners setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...sheet}/>
        </div>
    )
}

export default PreviewSheet;
