import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
                           children,
                           ...props
                       }) => {
    var iframe = document.getElementById(props.title);

    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');
        script.append(`
    Enabler.setProfileId(10799556);
    var devDynamicContent = {};

    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_ = [{}];
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0]._id = 0;
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].ColorTheme = "Grey/Navy";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].image_vertical = "${props.image_vertical}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].image_horizontal = "${props.image_horizontal}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].image_square = "${props.image_square}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].exitURLs = {};
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].exitURLs.Url = "${props.exiturls}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].Headline = "${props.headline}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].Subheadline_Present = ${props.subheadline_present};
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].Subheadline = "${props.subheadline}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].CTA = "${props.cta}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].URL_Tracking = "";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].Creative_Reporting_Label = "";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_[0].ExitURL_with_Reporting = "https://surveymonkey.com";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes = [{}];
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes[0]._id = 0;
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes[0].logo_vertical = "${props.logo_vertical}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes[0].logo_horizontal = "${props.logo_horizontal}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes[0].Background_Color = "${props.background_color}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes[0].Text_Color = "${props.text_color}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes[0].Outline_Color = "${props.outline_color}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes[0].CTA_Button_Color = "${props.cta_button_color}";
    devDynamicContent.Survey_Monkey_banner_options_feed_SVMK_Q42022_Color_Themes[0].CTA_Text_Color = "${props.cta_text_color}";
    Enabler.setDevDynamicContent(devDynamicContent);`
        );
        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            Array.from(document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.head.querySelectorAll('script')).pop().append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
