import React from 'react'
import { Link } from 'react-router-dom'
import getClients from '../../../../services/clientList'
import {Grid} from "@mui/material";

const previewSheetDesignList = (props) => {
    let clientsList = [];
    getClients(props.clients, clientsList);
    const linkProperties = [
                              {route: '/omni-design1-previews', title: 'Agency Only', client: 'Omni'},
                              {route: '/omni-design8-previews', title: 'Design 8', client: 'Omni'},
                              {route: '/omni-design4-previews', title: 'Design 4', client: 'Omni'},
                              {route: '/Prospecting-previews', title: 'Prospecting', client: 'Omni'},
                              {route: '/ProspectingQ42024-previews', title: 'Prospecting Q4 2024', client: 'Omni'},
                              {route: '/Retargeting-previews', title: 'Retargeting', client: 'Omni'},
    ]
  return (
      <Grid container rowSpacing={2} columnSpacing={5} sx={{justifyContent:"center"}}>
          {linkProperties.map((link) => {
              if (clientsList.includes(link.client)) {
                  return (
                      <Grid item xs={4} key={Math.random().toString(16).slice(2)}>
                          <Link to={link.route} key={Math.random().toString(16).slice(2)}>
                              <h1 key={Math.random().toString(16).slice(2)}>{link.title}</h1>
                          </Link>
                      </Grid>
                  )
          } else {
              return (<div key={Math.random().toString(16).slice(2)}></div>)
              }
          })}
      </Grid>
  );
}

export default previewSheetDesignList;
