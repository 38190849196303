import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
                           children,
                           ...props
                       }) => {
    var iframe = document.getElementById(props.title);

    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');
        script.append('    Enabler.setProfileId(10721695);\n' +
            '    var devDynamicContent = {};\n' +
            '\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After = [{}];\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0]._id = 0;\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].Unique_ID = 1;\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].Reporting_Label = "EXAMPLE";\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].isActive = false;\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].Start_Date = {};\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].Start_Date.RawValue = "5/1/2020";\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].Start_Date.UtcValue = 1588316400000;\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].End_Date = {};\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].End_Date.RawValue = "1/1/2029";\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].End_Date.UtcValue = 1861948800000;\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].image_before_vert = \'' + props.image_before_vert + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].image_after_vert = \'' + props.image_after_vert + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].image_before_horiz = \'' + props.image_before_horiz + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].image_after_horiz = \'' + props.image_after_horiz + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].exitURLs = ["https:\\/\\/www.containerstore.com\\/campaign\\/closet-organizer-sale"];\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].LogoType = \'' + props.logo_type + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].Headline = \'' + props.headline + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].CTA = \'' + props.cta + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].Background_Color = \'' + props.background_color + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].Headline_Color = \'' + props.headline_color + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].CTA_Button_Color = \'' + props.cta_button_color + '\';\n' +
            '    devDynamicContent.The_Container_Store_banner_options_feed_Before_and_After[0].CTA_Text_Color = \'' + props.cta_text_color + '\';\n' +
            '    Enabler.setDevDynamicContent(devDynamicContent);');
        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            Array.from(document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.head.querySelectorAll('script')).pop().append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
