import {FormColumn, FormRow, Label} from "../styles/styledMiscComponents";
import {Dropdown, Option} from "./Dropdown";
import {Button} from "@mui/material";
import React from "react";


export default function SelectColorTheme(props) {
    return (
        <FormRow>
            <FormColumn>
                <Label>Color Theme
                    <Dropdown onChange={(e) => props.setFormData({...props.formData, color_theme: e.target.value, colortheme: e.target.value, Color_Theme: e.target.value})}>
                        <Option defaultValue value={props.formData.color_theme || props.formData.colortheme || props.formData.Color_Theme}/>
                        {props.formData && props.formData.sheet ? props.formData.sheet.map((theme) => {
                            return (
                                <Option key={Math.random().toString(16).slice(2)} value={theme[props.themeColumnName]}/>
                            )}) : null }
                    </Dropdown>
                    <Button onClick={props.handleColorThemeSubmit} variant="contained">Change Color Scheme</Button>
                </Label>
            </FormColumn>
        </FormRow>
    )
}
