import { AdContainer, ButtonContainer, Shine, SidebarBottom, SidebarTop, ImageContainer, Image1, Logo, LogoContainer, HeadlineContainer, Headline1, Headline2, Button} from '../styles/styled_970x250'

export default function _728x90(props){
  return (
    <>
      <AdContainer>
          <SidebarTop primaryColor={props.primaryColor}></SidebarTop>
          <SidebarBottom econdaryColor={props.secondaryColor}></SidebarBottom>
          <ImageContainer>
              <Image1 image1Vertical={props.image1Vertical}></Image1>
          </ImageContainer>
          <LogoContainer primaryColor={props.primaryColor}>
              <Logo src={props.logoUrl}></Logo>
          </LogoContainer>
          <HeadlineContainer>
            <Headline1 primaryColor={props.primaryColor}>{props.headline1}</Headline1>
            <Headline2 primaryColor={props.primaryColor}>{props.headline2}</Headline2>
            <ButtonContainer>
                <Button primaryColor={props.primaryColor}>{props.CTA}</Button>
                <Shine></Shine>
            </ButtonContainer>
          </HeadlineContainer>
      </AdContainer>
    </>
    );
}