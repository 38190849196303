import React, { useState } from 'react'
import BannerContainer from './BannerContainer'
import OmniDataService from '../../../../../services/omniAxios.js'
import FormFields from "../../../../elements/FormFields";
import SaveSheet from "../../../../elements/saveSheet";
import {Note} from "../../../../styles/styledMiscComponents";

export default function Design1(props) {
  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));
  const [key160x600, setkey160x600] = useState(Math.random().toString(16).slice(2));

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
    setkey160x600(key160x600 + 1);
  }

  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle = "Q2_2023";
  const [formData, setFormData] = useState(false);
  const themeColumnName = 'Color_Theme'
  const saving = true;

  function formatFormData(data) {
    return {
      name: "",
      color_theme: data?.supplementary_row?.themeColumnName || "",
      primary_color: data?.supplementary_row?.primary_color || "",
      headline_1_color: data?.supplementary_row?.headline1_color || "",
      headline_2_color: data?.supplementary_row?.headline2_color || "",
      sale_duration_color: data?.supplementary_row?.sale_duration_color || "",
      legal_statement_color: data?.supplementary_row?.legal_statement_color || "",
      sticker_add_on_color: data?.supplementary_row?.sticker_add_on_color || "",
      sticker_container_color: data?.supplementary_row?.sticker_container_color || "",
      cta_color: data?.supplementary_row?.cta_color || "",
      cta_button_color: data?.supplementary_row?.cta_button_color || "",
      exit_url: data?.row?.exit_url || "",
      headline_1: data?.row?.headline_1 || "",
      headline_2: data?.row?.headline_2 || "",
      sale_present: data?.row?.sale_present.toLowerCase() || "",
      sale_duration: data?.row?.sale_duration || "",
      legal_statement_present: data?.row?.legal_statement_present.toLowerCase() || "",
      legal_statement: data?.row?.legal_statement || "",
      sticker_present: data?.row?.sticker_present.toLowerCase() || "",
      sticker_statement: data?.row?.sticker_statement || "",
      cta: data?.row?.cta || "",
      logo: data?.row?.logo || "",
      image_vert: data?.row?.image_vert || "",
      image_horiz: data?.row?.image_horiz || "",
      is_approved: data?.row?.IsApproved || "",
      is_default: data?.row?.IsDefault || "",
      retargeting_key: data?.row?.["Retargeting Key"] || "",
      start_date: data?.row?.StartDate || "",
      end_date: data?.row?.EndDate || "",
      reporting_label: data?.row?.reporting_label || "",
      sheet: data?.sheet || "",
      design: sheetTitle
    }
  }

  const handleColorThemeSubmit = (e) => {

    for (let theme of formData.sheet) {
      let cell = theme[themeColumnName]
      if (formData.color_theme === cell) {
        setFormData({
          color_theme: formData.color_theme,
          primary_color: theme.primary_color,
          headline_1_color: theme.headline1_color,
          headline_2_color: theme.headline2_color,
          sale_duration_color: theme.sale_duration_color,
          legal_statement_color: theme.legal_statement_color,
          sticker_add_on_color: theme.sticker_add_on_color,
          sticker_container_color: theme.sticker_container_color,
          cta_color: theme.cta_color,
          cta_button_color: theme.cta_button_color,
          exit_url: formData.exit_url,
          headline_1: formData.headline_1,
          headline_2: formData.headline_2,
          sale_present: formData.sale_present,
          sale_duration: formData.sale_duration,
          legal_statement_present: formData.legal_statement_present,
          legal_statement: formData.legal_statement,
          sticker_present: formData.sticker_present,
          sticker_statement: formData.sticker_statement,
          cta: formData.cta,
          logo: formData.logo,
          image_vert: formData.image_vert,
          image_horiz: formData.image_horiz,
          is_approved: formData.is_approved,
          is_default: formData.is_default,
          retargeting_key: formData.retargeting_key,
          start_date: formData.start_date,
          end_date: formData.end_date,
          reporting_label: formData.reporting_label,
          sheet: formData.sheet,
          design: sheetTitle
        })
      }
    }
    refreshAll();
  }

  const getSheet = async (e) => {
    return await OmniDataService.getDesignNothingBundtCakesQ22023Sheets();
  }

  const note = <Note>*You cannot save a new color theme here. You must save a new hotel code or change the hotel code / color mapping in the feed sheet.</Note>

  return (
      <div>
        <FormFields themeColumnName={themeColumnName} handleColorThemeSubmit={handleColorThemeSubmit} refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} note={note} saving={saving}/>
        {bannerActive &&
            <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
        {formData && bannerActive &&
            <BannerContainer setkey160x600={setkey160x600} key160x600={key160x600} setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
        }
      </div>
  );
}
