import  styled  from  'styled-components';
import { PackageButtonFade, PackageHeadline1, PackageHeadline2, PackageImageFade, PackageShine} from '../animations/Omni_animations'

export const AdContainer = styled.div`
    height: 248px;
    width: 968px;
    background-color: white;
    overflow: hidden;
    position: relative;
    border: 1px solid grey;
`

export const SidebarTop = styled.div`
    background-color: ${props => props.secondaryColor};
    position: absolute;
    height: 126px;
    width: 8px;
    z-index: 2;
    top: 126px;
    left: 310px;
`

export const SidebarBottom = styled.div`
    background-color: ${props => props.primaryColor}; 
    position: absolute;
    height: 125px;
    width: 8px;
    z-index: 2;
    left: 310px;
`

export const LogoContainer = styled.div`
    background-color: ${props => props.primaryColor}; 
    position: absolute;
    top: 0px;
    left: 680px;
    height: 252px;
    width: 300px;
    display: grid;
    place-items: center;
`

export const Logo = styled.img`
    width: 200px;
`

export const ImageContainer = styled.div`
    width: 310px;
    overflow: hidden;
`

export const Image1 = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 320px;
    height: 250px;
    opacity: 0;
    background-image: url(${props => props.image1Vertical});
    animation: ${PackageImageFade} 10s forwards;
`

export const HeadlineContainer = styled.div`
    position: relative;
    height: 100%;
    width: 370px;
    left: 310px;
    top: -250px;
`

export const Headline1 = styled.p`
    margin: 0;
    padding: 0;
    font-family: "Arquitecta-Bold";
    letter-spacing: .9px;
    padding: 0px 20px 0px 35px;
    opacity: 0;
    font-size: 40px;
    line-height: 33px;
    position: relative;
    top: 50px;
    width: 300px;
    color: ${props => props.primaryColor};
    animation: ${PackageHeadline1} 6s forwards;
`

export const Headline2 = styled.p`
    margin: 0;
    padding: 0;
    font-family: "Arquitecta-Bold";
    letter-spacing: .9px;
    padding: 0px 20px 0px 35px;
    position: relative;
    font-size: 30px;
    top: -20px;
    line-height: 23px;
    color: ${props => props.primaryColor};
    animation: ${PackageHeadline2} 6s forwards;
` 

export const ButtonContainer = styled.div`
    position: relative;
    padding: 0px 0px 0px 35px;
    height: 140px;
    bottom: 10px;
    margin-top: 5px;
`

export const Button = styled.div`
    margin-top: 20px;
    height: 33px;
    width: 156px;
    background: none;
    font-size: 15px;
    line-height: 25px;
    font-family: "Arquitecta-Book";
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${PackageButtonFade} 8s forwards;
    border: 1px solid ${props => props.primaryColor};
    color: ${props => props.primaryColor};
`

export const Shine = styled.div`
    position: relative;
    top: -35px;
    left: -5px;
    height: 40px;
    width: 20px;
    background: rgba(255, 255, 255, 0.4);
    animation: ${PackageShine} 8s forwards;
`