import { IFrame } from './IFrame';

export default function _320x50(props) {

    return (
        <>
            <IFrame {...props} iframenumber={2} width={"320"} height={"50"} title={"_320x50-Banner"} id={"_320x50-Banner"} src={'/Omni_Hotels/dynamic-banners-omni-hotels/Omni_Animated_Retargeting_V1/published/Omni_Prospecting_320x50/index.html'}/>
        </>
    );
}
