import React, { useState } from 'react'
import BannerContainer from './BannerContainer'
import OmniDataService from '../../../../../services/omniAxios.js'
import FormFields from "../../../../elements/FormFields";
import SaveSheet from "../../../../elements/saveSheet";

export default function Design1(props) {
  const [key160x600, setkey160x600] = useState(Math.random().toString(16).slice(2));
  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));

  let refreshAll = () => {
    setkey160x600(key160x600 + 1);
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
  }

  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle = "SVMK Q42022 ";
  const [formData, setFormData] = useState(false);
  const themeColumnName = 'ColorTheme';
  const saving = true;

  function formatFormData(data) {
    return {
      name: "",
      background_color: data?.supplementary_row?.Background_Color || "",
      text_color: data?.supplementary_row?.['Text Color'] || "",
      outline_color: data?.supplementary_row?.Outline_Color || "",
      cta_button_color: data?.supplementary_row?.CTA_Button_Color || "",
      cta_text_color: data?.supplementary_row?.CTA_Text_Color || "",
      logo_vertical: data?.supplementary_row?.logo_vertical || "",
      logo_horizontal: data?.supplementary_row?.logo_horizontal || "",
      cta: data?.row?.CTA || "",
      headline: data?.row?.Headline.replaceAll('"', '\\"') || "",
      subheadline: data?.row?.Subheadline?.replaceAll('"', '\\"') || "",
      subheadline_present: (String(data?.row?.['Subheadline Present']).toLowerCase() === 'true') || false,
      exiturls: data?.row?.exitURLs || "",
      image_square: data?.row?.image_square || "",
      image_vertical: data?.row?.image_vertical || "",
      image_horizontal: data?.row?.image_horizontal || "",
      reporting_label: data?.row?.Reporting_Label || "",
      is_default: data?.row?.isDefault || "",
      is_active: data?.row?.isActive || "",
      start_date: data?.row?.Start_Date || "",
      end_date: data?.row?.End_Date || "",
      placement_id: data?.row?.PlacementID || "",
      audience_id: data?.row?.AudienceID || "",
      campaign_id: data?.row?.CampaignID || "",
      url_tracking : data?.row?.["URL Tracking"] || "",
      color_theme: data?.supplementary_row?.[themeColumnName] || "",
      sheet: data?.sheet || "",
      design: sheetTitle
    }
  }

  const handleColorThemeSubmit = (e) => {
    for (let theme of formData.sheet) {
      console.log(theme)
      let cell = theme['ColorTheme']
      if (formData.color_theme === cell) {
        setFormData({
          name: "",
          background_color: theme.background_color,
          text_color: theme.text_color,
          outline_color: theme.outline_color,
          cta_button_color: theme.cta_button_color,
          cta_text_color: theme.cta_text_color,
          logo_vertical: theme.logo_vertical,
          logo_horizontal: theme.logo_horizontal,
          cta: formData.cta,
          headline: formData.headline,
          subheadline: formData.subheadline,
          subheadline_present: formData.subheadline_present,
          exiturls: formData.exiturls,
          image_square: formData.image_square,
          image_horizontal: formData.image_horizontal,
          image_vertical: formData.image_vertical,
          reporting_label: formData.reporting_label,
          is_default: formData.is_default,
          is_active: formData.is_active,
          start_date: formData.start_date,
          end_date: formData.end_date,
          placement_id: formData.placement_id,
          audience_id: formData.placement_id,
          campaign_id: formData.campaign_id,
          url_tracking : formData.url_tracking,
          color_theme: formData.color_theme,
          sheet: formData.sheet,
          design: sheetTitle
        })
      }
    }
    refreshAll();
  }

  const getSheet = async (e) => {
    return await OmniDataService.getDesignSMDesign9Sheets();
  }

  return (
      <div>
        <FormFields themeColumnName={themeColumnName} handleColorThemeSubmit={handleColorThemeSubmit} refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} saving={saving}/>
        {bannerActive &&
            <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
        {formData && bannerActive &&
            <BannerContainer setkey160x600={setkey160x600} key160x600={key160x600} setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
        }
      </div>
  );
}
