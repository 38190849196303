/** blank file for previewSheet for new Design. Includes flags for where code changes are needed. **/

import React, {useState, useEffect} from 'react'
import OmniDataService from '../../../../services/omniAxios.js'
import PreviewSheets from "../../PreviewSheets";


const PreviewSheetList = () => {
    const [sheets, setSheets] = useState([])

    useEffect(() => {
        retrieveSheetList();
    }, [])

    const retrieveSheetList = () => {
        /**  Match the Design Name**/
        OmniDataService.getDesignSMDesign8Sheets()
            .then(res => {
                setSheets(
                    res.data.sort(function (a, b) {
                        if (a._timestamp < b._timestamp) {
                            return -1;
                        }
                        if (a._timestamp > b._timestamp) {
                            return 1;
                        }
                        return 0;
                    }))
            })
    }

    const route = "/designSMDesign8sheets/";

    return (
        <PreviewSheets route={route} sheets={sheets} setSheets={setSheets} retrieveSheetList={retrieveSheetList}/>
    )
}

export default PreviewSheetList;
