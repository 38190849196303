import React from 'react'
import { Link } from 'react-router-dom'
import getClients from '../../services/clientList'
import {Grid} from "@mui/material";

const previewSheetDesignList = (props) => {
    let clientsList = [];
    getClients(props.clients, clientsList);
    const linkProperties = [
                            {route: '/TheContainerStore-preview-designs', title: 'The Container Store', client: 'Container Store'},
                            {route: '/SurveyMonkey-preview-designs', title: 'Survey Monkey', client: 'Surveymonkey'},
                            {route: '/omni-preview-designs', title: 'Omni Hotels', client: 'Omni'},
                            {route: '/Generic-preview-designs', title: 'Generic', client: 'Data Innovation'},
                            {route: '/NothingBundtCakes-preview-designs', title: 'Nothing Bundt Cakes', client: 'Nothing Bundt Cakes'},
                            {route: '/Test-preview-designs', title: 'Test', img: 'previews/Momentive1.png', client: 'Test'},
                            {route: '/Acme-preview-designs', title: 'Acme', img: 'previews/NothingBundtCakesQ22023.png', client: 'Acme'},]
  return (
      <Grid container rowSpacing={2} columnSpacing={5} sx={{justifyContent:"center"}}>
          {linkProperties.map((link) => {
              if (clientsList.includes(link.client)) {
                  return (
                      <Grid item xs={4} key={Math.random().toString(16).slice(2)}>
                          <Link to={link.route} key={Math.random().toString(16).slice(2)}>
                              <h1 key={Math.random().toString(16).slice(2)}>{link.title}</h1>
                          </Link>
                      </Grid>
                  )
          } else {
              return (<div key={Math.random().toString(16).slice(2)}></div>)
              }
          })}
      </Grid>
  );
}

export default previewSheetDesignList;
