import React from 'react'
import { Link } from 'react-router-dom'
import getClients from '../../../../services/clientList'
import {Grid, Box} from "@mui/material";

const previewSheetDesignList = (props) => {
    let clientsList = [];
    getClients(props.clients, clientsList);
    const linkProperties = [{route: '/Test-previews', title: 'Test', client: 'Test'},]
  return (
      <>
      {/*Ordering items in a box component (MUI) to display better on page*/}
      <Box
          component="section"
          sx={{
              padding: '40px',
              maxHeight: '100%',
              overflow: 'auto'
          }}>
          {linkProperties.map((link) => {
              if (clientsList.includes(link.client)) {
                  return (
                      <Grid key={Math.random().toString(16).slice(2)}>
                          <Link to={link.route} key={Math.random().toString(16).slice(2)}>
                              <h3 key={Math.random().toString(16).slice(2)}>{link.title}</h3>
                          </Link>
                      </Grid>
                  )
          } else {
              return (<div key={Math.random().toString(16).slice(2)}></div>)
              }
          })}
      </Box>
      </>
  );
}

export default previewSheetDesignList;
