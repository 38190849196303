import { AdContainer, SidebarBottom, SidebarTop, ImageContainer, Image1, Logo, LogoContainer, HeadlineContainer, Headline1, Headline2, Button} from '../styles/styled_728x90'

export default function _728x90(props){
  return (
    <>
      <AdContainer>
          <ImageContainer>
              <Image1 image1Vertical={props.image1Vertical}></Image1>
          </ImageContainer>
          <SidebarTop primaryColor={props.primaryColor}></SidebarTop>
          <SidebarBottom secondaryColor={props.secondaryColor}></SidebarBottom>
          <HeadlineContainer>
              <Headline1 primaryColor={props.primaryColor}>{props.headline1}</Headline1>
              <Headline2 primaryColor={props.primaryColor}>{props.headline2}</Headline2>
          </HeadlineContainer>
          <LogoContainer primaryColor={props.primaryColor}>
              <Logo src={props.logoUrl}></Logo>
              <Button>{props.CTA}</Button>
          </LogoContainer>
      </AdContainer>
    </>
    );
}