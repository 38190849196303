/** Default file for new banner creation. This file is copied and pasted into new banner directory via buildDesign automation **/

import React, { useState } from 'react'
import BannerContainer from './BannerContainer';
import OmniDataService from '../../../../../services/omniAxios.js';

import FormFields from "../../../../elements/FormFields";
import SaveSheet from "../../../../elements/saveSheet";


export default function Design1(props) {
  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
  }
  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle = "Before and After";
  const [formData, setFormData] = useState(false);
  const saving = true;

  function formatFormData(data) {
    return {
      name: "",
      image_before_vert : data?.row?.image_before_vert || "",
      image_after_vert : data?.row?.image_after_vert || "",
      image_before_horiz : data?.row?.image_before_horiz || "",
      image_after_horiz: data?.row?.image_after_horiz || "",
      exiturls: data?.row?.exitURLs || "",
      logo_type: data?.row?.LogoType || "",
      headline: data?.row?.Headline || "",
      cta: data?.row?.CTA || "",
      background_color: data?.row?.Background_Color || "",
      headline_color: data?.row?.Headline_Color || "",
      cta_button_color: data?.row?.CTA_Button_Color || "",
      cta_text_color: data?.row?.CTA_Text_Color || "",
      reporting_label: data?.row?.Reporting_Label || "",
      is_default: data?.row?.isDefault || "",
      is_active: data?.row?.isActive || "",
      start_date: data?.row?.Start_Date || "",
      end_date: data?.row?.End_Date || "",
      placement_id: data?.row?.PlacementID || "",
      audience_id: data?.row?.AudienceID || "",
      design: 'DesignTCS2022'
    }
  }

  const getSheet = async (e) => {
    return await OmniDataService.getDesignTCS2022Sheets();
  }

  return (
      <div>
        <FormFields refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} saving={saving}/>
        {bannerActive &&
            <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
        {formData && bannerActive &&
            <BannerContainer setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
        }
      </div>
  );
}

