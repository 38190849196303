import { IFrame } from './IFrame';

export default function _320x50(props) {

    return (
        <>
            <IFrame {...props} iframenumber={2} width={"320"} height={"50"} title={"_320x50-Banner"} id={"_320x50-Banner"} src={'/Omni_Hotels/dynamic-banners-omni-hotels/Prospecting_Q1/published/omni_Q12023_320x50/index.html'}/>
        </>
    );
}
