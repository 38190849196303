import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
                           children,
                           ...props
                       }) => {
    var iframe = document.getElementById(props.title);

    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');
        script.append('    Enabler.setProfileId(10831692);\n' +
            '    var devDynamicContent = {};\n' +
            '\n' +
            '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic = [{}];\n' +
            '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].UniqueID = 1;\n' +
            '        devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].Reporting_Label = "example_1";\n' +
            '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].isDefault = true;\n' +
            '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].isActive = true;\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].Start_Date = {};\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].Start_Date.RawValue = "05/11/2023";\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].Start_Date.UtcValue = 1683788400000;\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].End_Date = {};\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].End_Date.RawValue = "05/11/2024";\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].End_Date.UtcValue = 1715410800000;\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].PlacementID = "";\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].CampaignID = "";\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].AudienceID = "";\n' +
            '       devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].CreativeName = "";\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].product_images = [' + props.product_images + '];\n' +
            '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].exitURLs = [' + props.exitURLs + '];\n' +
            '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].primary_color = \'' + props.primary_color + '\';\n' +
            '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].product_prices = [' + props.product_prices + '];\n' +
            '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].product_names = [' + props.product_names + '];\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].product_sale_prices = [' + props.product_sale_prices + '];\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].secondary_color = \'' + props.secondary_color + '\';\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].logo = \'' + props.logo + '\';\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].headline = \'' + props.headline + '\';\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].headline_color = \'' + props.headline_color + '\';\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].URL_Tracking = "?program=7013A000000mweBQAQ&utm_kxconfid=s0f5occ14";\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].Creative_Reporting_Label = "retargeting_default_";\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].ExitURL_with_Reporting = "?program=7013A000000mweBQAQ&utm_kxconfid=s0f5occ14";\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].cta = \'' + props.cta + '\';\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].cta_button_color = \'' + props.cta_button_color + '\';\n' +
        '    devDynamicContent.Dynamic_social_2023_Generic_Dynamic[0].cta_text_color = \'' + props.cta_text_color + '\';\n' +
        '    Enabler.setDevDynamicContent(devDynamicContent);');
        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            Array.from(document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.head.querySelectorAll('script')).pop().append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
