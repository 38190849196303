import { IFrame } from './IFrame';

export default function _300x600(props){

  return (
      <>
        <IFrame{...props} iframenumber={4}  width={"300"} height={"600"} title={"_300x600-Banner"} id={"_300x600-Banner"} src={'/Omni_Hotels/dynamic-banners-omni-hotels/Omni_Animated_Retargeting_V1/published/Omni_Prospecting_300x600/index.html'}/>
      </>
  );
}
