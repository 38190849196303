
import styled from 'styled-components';

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Label = styled.label`
  color: #14a7b6;
  font-weight: bold;
  font-size: larger;
`

export const FormContainer = styled.div`
  box-shadow: .5px .5px 10px .5px grey;
  padding: 4rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  gap: 15%;
`


export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 10px;
  
`

export const Note = styled.div`
  text-align: center;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  gap: 10%;
`

export const DropdownWrapper = styled.form`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
`

export const StyledSelect = styled.select`
  max-width: 50%;
  height: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
`;

export const StyledOption = styled.option`
  color: ${(props) => (props.selected ? "lightgrey" : "black")};
`;

export const StyledLabel = styled.label`
  margin-bottom: 1rem;
`;

export const StyledButton = styled.input`
  max-width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  border: solid 2px blue;
  padding: 0.5rem;
  border-radius: 1rem;
`;

export const CustomH1 = styled.h1`
  color: #14A7B6;
  font-weight: bolder;
  font-size: 3rem;
  text-align: left;

`

export const PreviewRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10%;
`

export const PreviewColumn = styled.div`
  width: 50%;
  text-align: left;
  box-shadow: .2px .2px 5px .2px grey;
  padding: 2rem;
  margin: 0 5px 0 5px;
  background-color: #f5f5f5;
  border-radius: 10px;
`
export const PreviewItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Img = styled.img`
    display: flex;
    margin: 5px;
`
export const PreviewImgs = styled.div`
  display: flex;
`

export const PreviewHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const DesignForm = styled.div` 
  width: 75%;
  display: flex;
  justify-content: space-between;
`

export const FormItem = styled.div`
  display: flex;
`
