import { IFrame } from './IFrame';

export default function _300x250(props){

    return (
        <>
            <IFrame {...props} iframenumber={0} width={"300"} height={"250"} title={"_300x250-Banner"} id={"_300x250-Banner"} src={'/SurveyMonkey-Momentive/survey-monkey-momentive/smDesign9/Studio_Version/smdesign9_responsive/smdesign9_responsive_300x250/index.html'}/>
        </>
    );
}
