import React, {useState} from 'react'
import {createPortal} from 'react-dom'

export const IFrame = ({
                           children,
                           ...props
                       }) => {
    var iframe = document.getElementById(props.title);
    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');
        script.append(
            `function createContent() {
                        Enabler.setProfileId(10521571);
                        var devDynamicContent = {};
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1 = [{}];
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0]._id = 0;
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].Campaign_Budget_Source = "";
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].CampaignName = "AddedValue_AIPRST_spring_couples_getting_away";
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].StartDate = {};
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].StartDate.RawValue = "4/1/2020";
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].StartDate.UtcValue = 1585724400000;
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].EndDate = {};
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].EndDate.RawValue = "1/1/2022";
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].EndDate.UtcValue = 1641024000000;
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].exit_url = {};
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].exit_url.Url = "https://www.omnihotels.com/hotels/amelia-island-plantation";
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].background_color = '${props.secondary_color}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].copy_color = '${props.primary_color}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].top_copy = '${props.headline_1}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].bottom_copy = '${props.headline_2}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].cta = '${props.cta}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].cta_button_color = '${props.cta_button_color}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].cta_text_color = '${props.cta_text_color}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].logo_vert = '${props.logo_vert}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].logo_horiz = '${props.logo_horiz}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].image_vert = '${props.image_vert}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].image_horiz = '${props.image_horiz}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].reporting_label = "1";
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].AdjustImage300x250 = '${props.adjust_image_300x250}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].AdjustImage970x250 = '${props.adjust_image_970x250}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].AdjustImage728x90 = '${props.adjust_image_728x90}';
                    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Design_1[0].AdjustImage300x600 = '${props.adjust_image_300x600}';
                        Enabler.setDevDynamicContent(devDynamicContent);
                        populateContent();
                    }
                    try {
                    createContent();
                    } catch (e) {};`)
        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.body.querySelectorAll('script')[0].append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
