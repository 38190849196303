import { IFrame } from './IFrame';

export default function _160x600(props){

  return (
      <>
        <IFrame{...props} iframenumber={6}  width={"160"} height={"600"} title={"_160x600-Banner"} id={"_160x600-Banner"} src={'/NothingBundtCakes/nothingbundtcakes_banners/Q22023/studio/nbc_responsive/nbc_responsive_160x600/index.html'}/>
      </>
  );
}
