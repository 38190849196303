import React, { useState } from 'react'
import BannerContainer from './BannerContainer'
import OmniDataService from '../../../../../services/omniAxios.js'
import FormFields from "../../../../elements/FormFields";
import SaveSheet from "../../../../elements/saveSheet";

export default function Design1(props) {
  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));
  const [key160x600, setkey160x600] = useState(Math.random().toString(16).slice(2));

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
    setkey160x600(key160x600 + 1);
  }
  const [bannerActive, setBannerActive] = useState(false);
  const [blankForm, setBlankForm] = useState(false);
  const sheetTitle= "2023 Generic Dynamic+";
  const [formData, setFormData] = useState(false);
  const saving = true;

  function formatFormData(data) {
    return {
      name: "",
      product_images: data?.row?.product_images || "",
      exiturls: data?.row?.exitURLs|| "",
      primary_color: data?.row?.primary_color|| "",
      logo: data?.row?.logo|| "",
      product_prices: data?.row?.product_prices|| "",
      product_names: data?.row?.product_names|| "",
      product_sale_prices: data?.row?.product_sale_prices|| "",
      secondary_color: data?.row?.secondary_color|| "",
      headline: data?.row?.headline|| "",
      headline_color: data?.row?.headline_color|| "",
      cta: data?.row?.cta|| "",
      cta_button_color: data?.row?.cta_button_color|| "",
      cta_text_color: data?.row?.cta_text_color|| "",
      reporting_label: data?.row?.Reporting_Label|| "",
      is_default: data?.row?.isDefault|| "",
      is_active: data?.row?.isActive|| "",
      start_date: data?.row?.Start_Date|| "",
      end_date: data?.row?.End_Date|| "",
      placement_id : data?.row?.PlacementID|| "",
      campaign_id : data?.row?.CampaignID|| "",
      audience_id : data?.row?.AudienceID|| "",
      creative_name : data?.row?.CreativeName|| "",
      design: sheetTitle
    }
  }

  const getSheet = async (e) => {
    return await OmniDataService.getDesignGeneric_BannersSheets();
  }


  return (
      <div>
        <FormFields refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} blankForm={blankForm} setBlankForm={setBlankForm} sheetTitle={sheetTitle} saving={saving}/>
        {bannerActive &&
            <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive} blankForm={blankForm} setBlankForm={setBlankForm}/>}
        {formData && bannerActive &&
            <BannerContainer setkey160x600={setkey160x600} key160x600={key160x600} setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
        }
      </div>
  );
}

