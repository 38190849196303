import React, { useState } from 'react'
import OmniDataService from '../../../../../services/omniAxios.js'
import FormFields from "../../../../elements/FormFields";
import SaveSheet from "../../../../elements/saveSheet";
import BannerContainer from "./OmniBannerContainer";

export default function Design1(props) {

  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
  }

  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle="Agency Only";
  const [formData, setFormData] = useState(false);
  const saving = false;

  function formatFormData(data) {
    return {
      name: "",
      cta: data?.row?.cta || "",
      headline_1: data?.row?.top_copy || "",
      headline_2: data?.row?.bottom_copy || "",
      logo_vert: data?.row?.logo_vert || "",
      logo_horiz: data?.row?.logo_horiz || "",
      primary_color: data?.row?.copy_color || "",
      secondary_color: data?.row?.background_color || "",
      image_horiz: data?.row?.image_horiz || "",
      image_vert: data?.row?.image_vert || "",
      cta_button_color: data?.row?.cta_button_color || "",
      cta_text_color: data?.row?.cta_text_color || "",
      adjust_image_300x250: data?.row?.AdjustImage300x250 || "",
      adjust_image_970x250: data?.row?.AdjustImage970x250 || "",
      adjust_image_728x90: data?.row?.AdjustImage728x90 || "",
      adjust_image_300x600: data?.row?.AdjustImage300x600 || "",
      hotel_codes: data?.row?.HotelCodes || "",
      retargeting_url: data?.row?.Retargeting_URL || "",
      start_date: data?.row?.StartDate || "",
      end_date: data?.row?.EndDate || "",
      exit_url: data?.row?.exit_url || "",
      reporting_label: data?.row?.reporting_label || "",
      design: sheetTitle
    }
  }

  const getSheet = async (e) => {
    return await OmniDataService.getDesign1Sheets();
  }

  return (
      <div>
        <FormFields refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} saving={saving}/>
        {bannerActive &&
            <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
        {formData && bannerActive &&
            <BannerContainer setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
        }
      </div>
  );
}
