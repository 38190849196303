import { IFrame } from './IFrame';

export default function _300x600(props) {


    return (
      <>
        <IFrame {...props} iframenumber={4} src={"/Omni_Hotels/dynamic-banners-omni-hotels/Design1/design1_300x600/index.html"} id={"_300x600-Banner"} title={"_300x600-Banner"} width={"300"} height={"600"} />
      </>
  );
}
