import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
                           children,
                            ...props
                       }) => {
    var iframe = document.getElementById(props.title);

    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');

        script.append(`
    Enabler.setProfileId(10821977);
    var devDynamicContent = {};

    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos = [{}];
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0]._id = 0;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].Unique_ID = 1;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].Color_Theme = "Sunlight";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].primary_color = "${props.primary_color}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].secondary_color = "${props.secondary_color}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].headline_color = "${props.headline_color}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].cta_text_color = "${props.cta_text_color}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].cta_button_color = "${props.cta_button_color}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].Logo_Vertical = "${props.logo_vertical}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].Logo_Horizontal = "${props.logo_horizontal}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_Hotel_Code_Themes__Logos[0].Logo_Mobile = "${props.logo_mobile}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023 = [{}];
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0]._id = 0;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].Unique_ID = 1;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].HotelCodes_Theme = "AIPRST";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].Campaign_Budget_Source = "";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].CampaignName = "test";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].StartDate = {};
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].StartDate.RawValue = "05/09/2023";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].StartDate.UtcValue = 1683615600000;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].EndDate = {};
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].EndDate.RawValue = "05/09/2024";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].EndDate.UtcValue = 1715238000000;
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].Exit_URL = "${props.exit_url}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].Headline = "${props.headline}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].CTA = "${props.cta}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].Image_1000x250 = "${props.image_1000x250}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].Image_634x300 = "${props.image_634x300}";
    devDynamicContent.Omni_Hotels_Dynamic_Banner_Feed_Prospecting_2023[0].Reporting_Label = "test";
    Enabler.setDevDynamicContent(devDynamicContent);`)

        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            Array.from(document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.head.querySelectorAll('script')).pop().append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
