import React from 'react'
import {Link} from 'react-router-dom'
import { Grid } from '@mui/material/';
import getClients from '../../services/clientList'

const Form = (props) => {
    let clientsList = [];
    getClients(props.clients, clientsList);
    const designProperties = [{route: '/TheContainerStore', title: 'The Container Store', img: 'previews/TCS2022.png', alt: 'Design TCS Preview', client: 'Container Store'},
                              {route: '/SurveyMonkey', title: 'Survey Monkey', img: 'previews/SMDesign8.png', alt: 'Design Survey Monkey Preview', client: 'Surveymonkey'},
                              {route: '/omni', title: 'Omni Hotels', img: 'previews/Design1.png', alt: 'Omni Hotels Preview', client: 'Omni'},
                              {route: '/Generic', title: 'Generic', img: 'previews/Momentive1.png', alt: 'Generic Banners Preview', client: 'Data Innovation'},
                              {route: '/NothingBundtCakes', title: 'Nothing Bundt Cakes', img: 'previews/NothingBundtCakesQ22023.png', client: 'Nothing Bundt Cakes'},
                              {route: '/Test', title: 'Test', img: 'previews/Momentive1.png', client: 'Test'},
                              {route: '/Acme', title: 'Acme', img: 'previews/NothingBundtCakesQ22023.png', client: 'Acme'},]

        return (
        <Grid container rowSpacing={2} columnSpacing={5} sx={{justifyContent:"center"}}>
            {designProperties.map((design) => {
                if (clientsList.includes(design.client)) {
                    return (
                    <Grid item xs={4} key={Math.random().toString(16).slice(2)}>
                        <Link to={design.route} key={Math.random().toString(16).slice(2)}>
                            <img width="300" height="250" src={design.img} alt={design.alt}
                                 key={Math.random().toString(16).slice(2)}/>
                        </Link>
                        <h1 key={Math.random().toString(16).slice(2)}>{design.title}</h1>
                    </Grid>
            )} else {
                    return (<div key={Math.random().toString(16).slice(2)}></div>)
                }
            })}

        </Grid>
    );
}

export default Form;
