import { IFrame } from './IFrame';

export default function _970x250(props){

    return (
        <>
            <IFrame {...props} iframenumber={3} width={"970"} height={"250"} title={"_970x250-Banner"} id={"_970x250-Banner"} src={'/NothingBundtCakes/nothingbundtcakes_banners/Q22023/studio/nbc_responsive/nbc_responsive_970x250/index.html'}/>
        </>
    );
}
