import { IFrame } from './IFrame';

export default function _300x600(props){

  return (
      <>
        <IFrame{...props} iframenumber={4}  width={"300"} height={"600"} title={"_300x600-Banner"} id={"_300x600-Banner"} src={'/Generic_Banners/generic_banner/Design1/Studio_Version/generic_responsive/index.html'}/>
      </>
  );
}
