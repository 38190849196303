import { IFrame } from './IFrame';

export default function _970x250(props){

    return (
        <>
            <IFrame {...props} iframenumber={3} width={"970"} height={"250"} title={"_970x250-Banner"} id={"_970x250-Banner"} src={'/SurveyMonkey-Momentive/survey-monkey-momentive/Momentive1/Studio_Version/momentive_responsive/index.html'}/>
        </>
    );
}
