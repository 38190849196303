import { AdContainer, TopBar, Image1, Image2, Logo, LogoContainer, HeadlineContainer, Headline1, Headline2, Button, ButtonContainer, Shine, SideBar, SideBarTop } from '../styles/styled_300x600'

export default function _300x600(props){
  return (
    <>
      <AdContainer>
        <TopBar primaryColor={props.primaryColor}></TopBar>
        <Image1 image1Vertical={props.image1Vertical}></Image1>
        <LogoContainer primaryColor={props.primaryColor}>
          <Logo src={props.logoUrl}></Logo>
        </LogoContainer>
        <HeadlineContainer>
          <Headline1 primaryColor={props.primaryColor}>{props.headline1}</Headline1>
          <Headline2 primaryColor={props.primaryColor}>{props.headline2}</Headline2>
        </HeadlineContainer>
        <ButtonContainer>
          <Button primaryColor={props.primaryColor}>{props.CTA}</Button>
              <Shine></Shine>
        </ButtonContainer> 
        <Image2 image2Vertical={props.image2Vertical}></Image2>
        <SideBar>
          <SideBarTop primaryColor={props.primaryColor}></SideBarTop>
          <SideBarTop primaryColor={props.primaryColor}></SideBarTop>
        </SideBar>
      </AdContainer>
    </>
    );
}