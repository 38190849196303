import { IFrame } from './IFrame';

export default function _300x50(props) {

    return (
        <>
            <IFrame {...props} iframenumber={5} width={"300"} height={"50"} title={"_300x50-Banner"} id={"_300x50-Banner"} src={'/Omni_Hotels/dynamic-banners-omni-hotels/Omni_Animated_Retargeting_V1/published/Omni_Prospecting_300x50/index.html'}/>
        </>
    );
    }

