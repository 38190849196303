import { IFrame } from './IFrame';

export default function _300x250(props){

    return (
        <>
            <IFrame {...props} iframenumber={0} width={"300"} height={"250"} title={"_300x250-Banner"} id={"_300x250-Banner"} src={'/TheContainerStore/the-container-store/2022_Design/Studio_Version/TCS_Banner_Updates_Responsive/index.html'}/>

        </>
    );
}
