import {
    CustomH1,
    Img,
    Label,
    PreviewHeader,
    PreviewImgs,
    PreviewItem
} from "../styles/styledMiscComponents";
import {Alert, Box} from "@mui/material"
import React, {useState} from "react";
import {Link} from "react-router-dom";
import OmniDataService from "../../services/omniAxios";



export default function PreviewSheets(props) {
    const [showAlert, setShowAlert] = useState({
        alert : null,
        _id : null
    })

    const deleteSheet = (sheet) => {
        OmniDataService.deleteSheet(sheet).then(res => {
            props.retrieveSheetList();
        }).catch(e => {
            console.log(e);
        });
    }
    function exportToCSV() {
        let filename = 'previewsheets.csv'
        let rows = [['Sheet Name', 'URLs','Created']];
        props.sheets.forEach(sheet => {
            //Making the timestamp object more readable. i.e.  [2023-06-28T15:53:28.000Z -> 6/28/2023 3:53:28 PM ]
            let timeAsDateObject = new Date(sheet._timestamp);
            let timeReadable = timeAsDateObject.toLocaleDateString() + ' ' + timeAsDateObject.toLocaleTimeString()

            rows.push([sheet.name, window.location.origin + props.route + sheet._id, timeReadable])
        })

        var processRow = function (row) {
            return row + "\r\n";
        };

        var csvFile = '';
        for (var i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }

        var blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }




    return (
        <>
            <PreviewHeader>
                <CustomH1>Saved sheet list</CustomH1>
                <PreviewItem>
                    <Label>Export</Label>
                    <Img height={"20px"} src={"/download.svg"} onClick={exportToCSV}/>
                </PreviewItem>
            </PreviewHeader>
            <Box
                component="section"
                sx={{
                bgcolor: 'white',
                border: '2px solid',
                boxShadow:'5px 3px 3px gray',
                padding: '40px',
                maxHeight: '100%',
                overflow: 'auto'
            }}>
                    {props.sheets.map((sheet) => {
                        return (
                            <PreviewItem key={Math.random().toString(16).slice(2)}>
                                <Link to={props.route + sheet._id} key={Math.random().toString(16).slice(2)}>
                                    <h5 key={Math.random().toString(16).slice(2)}>
                                        {sheet.name}
                                    </h5>
                                </Link>
                                {sheet._timestamp}
                                <PreviewImgs key={Math.random().toString(16).slice(2)}>
                                    <Img src={"/trash.svg"} onClick={() => deleteSheet(sheet._id)} key={Math.random().toString(16).slice(2)}/>
                                    <Img src={"/share-fill.svg"} key={Math.random().toString(16).slice(2)} onClick={async () => {
                                        await navigator.clipboard.writeText(window.location.origin + props.route + sheet._id);
                                        setShowAlert({
                                            alert : true,
                                            _id: sheet._id
                                        })
                                    }}/>

                                    {showAlert.alert && showAlert._id === sheet._id && <Alert key={Math.random().toString(16).slice(2)} severity="success">Copied!</Alert>}
                                </PreviewImgs>
                            </PreviewItem>
                        )
                    })}
            </Box>
        </>
    )
}
