import { Stack } from "@mui/material/"

import Generic300x600 from './_300x600';
import Generic300x250 from './_300x250';
import Generic300x50 from './_300x50';
import Generic320x50 from './_320x50';
import Generic728x90 from './_728x90';
import Generic970x250 from './_970x250';
import Generic160x600 from './_160x600';
import {BannerContainer} from "../../../../styles/styledMiscComponents";
import React from "react";
import {Button} from "@mui/material";

export default function GenericBannerContainer(props) {


    let buttonClicked300x250 = () => {
        props.setkey300x250(props.key300x250+1);
    }
    let buttonClicked728x90 = () => {
        props.setkey728x90(props.key728x90+1);
    }
    let buttonClicked320x50 = () => {
        props.setkey320x50(props.key320x50+1);
    }
    let buttonClicked920x50 = () => {
        props.setkey970x250(props.key970x250+1);
    }
    let buttonClicked300x600 = () => {
        props.setkey300x600(props.key300x600+1);
    }
    let buttonClicked300x50 = () => {
        props.setkey300x50(props.key300x50+1);
    }
    let buttonClicked160x600 = () => {
        props.setkey160x600(props.key160x600+1);
    }
    let refreshAll = () => {
        props.setkey300x50(props.key300x50+1);
        props.setkey300x600(props.key300x600+1);
        props.setkey970x250(props.key970x250+1);
        props.setkey320x50(props.key320x50+1);
        props.setkey728x90(props.key728x90+1);
        props.setkey300x250(props.key300x250+1);
        props.setkey160x600(props.key160x600+1);
    }

    const { setkey300x600, setkey300x250, setkey300x50, setkey320x50, setkey728x90, setkey970x250, setkey160x600, ...otherProps } = props;

    return(
        <div>
            <Stack alignItems="center" direction="column" spacing={2}>
                <Button sx={{backgroundColor: "#14A7B6", width: "20%", alignSelf: "center"}} onClick={refreshAll} variant="contained" >REFRESH ALL</Button>
                <BannerContainer>
                    <Generic300x250 key={props.key300x250} {...otherProps}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}} size={"small"} onClick={buttonClicked300x250} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
                    <Generic728x90 key={props.key728x90} {...otherProps}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}} size={"small"}  onClick={buttonClicked728x90} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
                    <Generic320x50 key={props.key320x50} {...otherProps}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}} size={"small"}  onClick={buttonClicked320x50} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
`                   <Generic970x250 key={props.key970x250} {...otherProps}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}}  size={"small"}  onClick={buttonClicked920x50} variant="contained">Refresh</Button>`
                </BannerContainer>
                <BannerContainer>
                    <Generic300x600 key={props.key300x600} {...otherProps}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}}  size={"small"}  onClick={buttonClicked300x600} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
                    <Generic300x50 key={props.key300x50} {...otherProps}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}}  size={"small"}  onClick={buttonClicked300x50} variant="contained">Refresh</Button>
                </BannerContainer>
                <BannerContainer>
                    <Generic160x600 key={props.key160x600} {...otherProps}/>
                    <Button sx={{backgroundColor: "#14A7B6", alignSelf : "flex-end", borderTopLeftRadius:"0", borderTopRightRadius:"0"}}  size={"small"}  onClick={buttonClicked160x600} variant="contained">Refresh</Button>
                </BannerContainer>
            </Stack>
        </div>
    )
}
