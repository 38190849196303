import  styled  from  'styled-components';
import { PackageButtonFade, PackageHeadline1, PackageHeadline2, PackageImageFade, PackageShine, BarFade} from '../animations/Omni_animations'

export const AdContainer = styled.div`
    height: 598px;
    width: 298px;
    background-color: white;
    overflow: hidden;
    position: relative;
    border: 1px solid grey;
`

export const TopBar = styled.div`
    background-color: ${props => props.primaryColor};
    position: absolute;
    height: 7px;
    width: 300px;
    z-index: 2;
`

export const LogoContainer = styled.div`
    background-color: ${props => props.primaryColor};
    position: absolute;
    top: 7px;
    left: 60px;
    height: 60px;
    width: 180px;
    display: grid;
    place-items: center;
`

export const Logo = styled.img`
    width: 140px;
`

export const Image1 = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(${props => props.image1Vertical});
    width: 300px;
    height: 250px;
    opacity: 0;
    animation: ${PackageImageFade} 10s forwards;
`

export const Image2 = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url(${props => props.image2Vertical});
    width: 300px;
    height: 160px;
    display: flex;
    justify-content: flex-end;
    z-index: -2;
    animation: ${PackageImageFade} 10s forwards;
`
export const HeadlineContainer = styled.div`
    display: grid;
    place-items: center;
    height: 120px;
    width: 300px;
    margin-top: 5px;
`

export const Headline1 = styled.p`
    color: ${props => props.primaryColor};
    margin: 0;
    padding: 0;
    font-family: "Arquitecta-Bold";
    letter-spacing: 1px;
    text-align: center;
    opacity: 0;
    font-size: 36px;
    line-height: 30px;
    position: relative;
    top: 30px;
    animation: ${PackageHeadline1} 6s forwards;
`

export const Headline2 = styled.p`
    color: ${props => props.primaryColor};
    margin: 0;
    padding: 0;
    font-family: "Arquitecta-Bold";
    letter-spacing: 1px;
    text-align: center;
    position: relative;
    font-size: 23px;
    top: -30px;
    line-height: 19px;
    animation: ${PackageHeadline2} 6s forwards;
`
export const ButtonContainer = styled.div`
    display: grid;
    place-items: center;
    height: 70px;
    position: relative;
    top: -5px;
`

export const Button = styled.div`
    margin-top: 5px;
    height: 33px;
    width: 156px;
    background: none;
    font-size: 15px;
    line-height: 25px;
    font-family: "Arquitecta-Book";
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.primaryColor};
    color: ${props => props.primaryColor};
    animation: ${PackageButtonFade} 8s forwards;
`

export const Shine = styled.div`
    position: relative;
    top: -26px;
    left: -60px;
    height: 20px;
    width: 20px;
    background: rgba(255, 255, 255, 0.4);
    animation: ${PackageShine} 8s forwards;
`

export const SideBar = styled.div`
    height: 156px;
    width: 7px;
    position: absolute;
    left: 292px;
    top: 442px;
`

export const SideBarTop = styled.div`
    width: 7px;
    height: 78px;
    z-index: 2;
    background-color: ${props => props.primaryColor};
    animation: ${BarFade} 1s forwards;
`