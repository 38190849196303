import React, { useState } from 'react'
import BannerContainer from './OmniBannerContainer'
import OmniDataService from '../../../../../services/omniAxios.js'

import FormFields from "../../../../elements/FormFields";
import SaveSheet from "../../../../elements/saveSheet";

export default function Design8() {
  const [key300x250, setkey300x250] = useState(Math.random().toString(16).slice(2));
  const [key728x90, setkey728x90] = useState(Math.random().toString(16).slice(2));
  const [key320x50, setkey320x50] = useState(Math.random().toString(16).slice(2));
  const [key970x250, setkey970x250] = useState(Math.random().toString(16).slice(2));
  const [key300x600, setkey300x600] = useState(Math.random().toString(16).slice(2));
  const [key300x50, setkey300x50] = useState(Math.random().toString(16).slice(2));

  let refreshAll = () => {
    setkey300x50(key300x50 + 1);
    setkey300x600(key300x600 + 1);
    setkey970x250(key970x250 + 1);
    setkey320x50(key320x50 + 1);
    setkey728x90(key728x90 + 1);
    setkey300x250(key300x250 + 1);
  }

  const [bannerActive, setBannerActive] = useState(false);
  const sheetTitle = "Design8_Package";
  const [formData, setFormData] = useState(false);
  const saving = false;

  function formatFormData(data) {
    return {
      name: "",
      cta: data?.row?.CTA || "",
      headline1: data?.row?.Headline1 || "",
      headline2: data?.row?.Headline2 || "",
      logoUrl: data?.row?.Logo || "",
      primaryColor: data?.row?.PrimaryColor || "",
      secondaryColor: data?.row?.SecondaryColor || "",
      image1Horizontal: data?.row?.Image1Horizontal || "",
      image1Vertical: data?.row?.Image1Vertical || "",
      image2Vertical: data?.row?.Image2Vertical || "",
      ctaButtonColor: data?.row?.ctaButtonColor || "",
      ctaTextColor: data?.row?.ctaTextColor || "",
      HotelCodes : data?.row?.HotelCodes || "",
      Retargeting_URL : data?.row?.Retargeting_URL || "",
      ExitURL : data?.row?.ExitURL || "",
      design: sheetTitle
    }
  }

  const getSheet = async (e) => {
    return await OmniDataService.getDesign8Sheets();
  }


  return (
      <div>
        <FormFields refreshAll={refreshAll} formData={formData} setFormData={setFormData} formatFormData={formatFormData} bannerActive={bannerActive} setBannerActive={setBannerActive} sheetTitle={sheetTitle} saving={saving}/>
        {bannerActive &&
            <SaveSheet formData={formData} getSheet={getSheet} setBannerActive={setBannerActive} bannerActive={bannerActive}/>}
        {formData && bannerActive &&
            <BannerContainer setkey728x90={setkey728x90} key728x90={key728x90} setkey970x250={setkey970x250} key970x250={key970x250} key320x50={key320x50} setkey320x50={setkey320x50} key300x50={key300x50} setkey300x50={setkey300x50} key300x600={key300x600} setkey300x600={setkey300x600} setkey300x250={setkey300x250} key300x250={key300x250} {...formData}/>
        }
      </div>
  );
}

