import React from 'react'
import { Card, CardContent, Button, Typography } from '@mui/material/'
import logo from '../../images/PMG_Logo_RGB_FullColor_White_170_RLSD (1).png'

export default function Login() {
    const login = () => {
        const backend_url = process.env.REACT_APP_BACKEND_URL || '';
        window.open(backend_url + "/api/v1/dynamic-previews/login", "_self");
    }
    return (
        <Card sx={{ backgroundColor:"#2f2f2f" }}>
            <CardContent sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                <img style={{width:"25%"}} src={logo} alt="logo"/>
                <Typography sx={{color: "white", fontSize:"24px"}}>Login to Dynamic Hub</Typography>
                <Button onClick={login} sx={{mt:5, mb:3, backgroundColor:"white", color:"black",
                             "&:hover": {color:"white", border:"2px solid white", borderRadius:"10px"}}}>Login with Alli</Button>
            </CardContent>
        </Card>        
    )
}
