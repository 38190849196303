import { IFrame } from './IFrame';

export default function _320x50(props) {

    return (
        <>
            <IFrame {...props} iframenumber={2} width={"320"} height={"50"} title={"_320x50-Banner"} id={"_320x50-Banner"} src={'/NothingBundtCakes/nothingbundtcakes_banners/Q22023/studio/nbc_responsive/nbc_responsive_320x50/index.html'}/>
        </>
    );
}
