import { IFrame } from './IFrame';

export default function _300x50(props) {

    return (
        <>
            <IFrame {...props} iframenumber={5} width={"300"} height={"50"} title={"_300x50-Banner"} id={"_300x50-Banner"} src={'/TheContainerStore/the-container-store/2022_Design/Studio_Version/TCS_Banner_Updates_Responsive/index.html'}/>
        </>
    );
    }

