import  styled  from  'styled-components';
import { PackageButtonFade, PackageHeadline1, PackageHeadline2, PackageImageFade} from '../animations/Omni_animations'

export const AdContainer = styled.div`
    height: 88px;
    width: 726px;
    background-color: white;
    overflow: hidden;
    position: relative;
    border: 1px solid grey;
`

export const ImageContainer = styled.div`
    width: 200px;
    height: 90px;
    overflow: hidden;
    position: relative;
`

export const Image1 = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 200px;
    height: 90px;
    background-image: url(${props => props.image1Vertical});
    animation: ${PackageImageFade} 10s forwards;
`

export const SidebarTop = styled.div`
    background-color: ${props => props.primaryColor};
    position: absolute;
    left: 200px;
    top: 0;
    height: 45px;
    width: 6px;
`

export const SidebarBottom = styled.div`
    background-color: ${props => props.secondaryColor};   
    position: absolute;
    left: 200px;
    top: 45px;
    height: 47px;
    width: 6px;
`

export const HeadlineContainer = styled.div`
    height: 90px;
    width: 220px;
    position: absolute;
    left: 230px;
    top: 0;
`

export const Headline1 = styled.p`
    margin: 0;
    padding: 0;
    font-family: "Arquitecta-Bold";
    letter-spacing: .8px;
    opacity: 0;
    font-size: 30px;
    line-height: 25px;
    margin-top: 16px;
    color: ${props => props.primaryColor};
    animation: ${PackageHeadline1} 6s forwards;
` 

export const Headline2 = styled.p`
    margin: 0;
    padding: 0;
    font-family: "Arquitecta-Bold";
    letter-spacing: .8px;
    position: relative;
    top: -50px;
    font-size: 22px;
    line-height: 18px;
    color: ${props => props.primaryColor};
    animation: ${PackageHeadline2} 6s forwards;
`

export const LogoContainer = styled.div`
    background-color: ${props => props.primaryColor}; 
    position: absolute;
    left: 508px;
    top: 0;
    height: 92px;
    width: 222px;
    display: grid;
    place-items: center;
`

export const Logo = styled.img`
    margin-top: 5px;
    width: 140px;
`

export const Button = styled.div`
    margin-top: 0px;
    margin-bottom: 8px;
    height: 22px;
    width: 100px;
    background: none;
    font-size: 11px;
    font-family: "Arquitecta-Book";
    animation: ${PackageButtonFade} 8s forwards;
    display: flex;
    justify-content: center;
    align-items: center;  
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
`