import React, { useState } from 'react'
import { createPortal } from 'react-dom'

export const IFrame = ({
                           children,
                           ...props
                       }) => {
    var iframe = document.getElementById(props.title);

    if (iframe) {
        const iframeWin = iframe.contentWindow || iframe;
        iframeWin.console.log = function() { /* nop */ }; //silences all console logs from iframe
        iframeWin.onerror= function(error) {
            iframeWin.location.reload();
            return true;
        }
        const iframeDoc = iframe.contentDocument || iframeWin.document;
        var script = iframeDoc.createElement('script');
        script.append(`
            Enabler.setProfileId(10711762);
            var devDynamicContent = {};
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new = [{}];
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0]._id = 0;
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].Location = "";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].image_vert = "${props.image_vert}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].image_horiz = "${props.image_horiz}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].video_vert = "";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].video_horiz = "";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].exitURLs = "https:\\/\\/www.surveymonkey.com\\/pricing";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].LogoType_Vert ="${props.logotype_vert}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].LogoType_Horiz ="${props.logotype_horiz}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].Headline ="${props.headline}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].Subheadline ="${props.subheadline}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].CTA ="${props.cta}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].Primary_Color ="${props.primary_color}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].Secondary_Color ="${props.secondary_color}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].CTA_Button_Color ="${props.cta_button_color}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].CTA_Text_Color ="${props.cta_text_color}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].URL_Tracking = "?program=7013A000000mweBQAQ&utm_kxconfid=s0f5occ14";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].Creative_Reporting_Label = "retargeting_default_ImpressSurveyData_CTATest_CR-8675_default";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].ExitURL_with_Reporting = "https:\\/\\/www.surveymonkey.com\\/pricing?program=7013A000000mweBQAQ&utm_kxconfid=s0f5occ14";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].texture_url ="${props.texture_url}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].column_color ="${props.column_color}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].logo_mobile_horiz ="${props.logo_mobile_horiz}";
            devDynamicContent.Survey_Monkey_banner_options_feed_SM_Design_2022_new[0].logo_mobile_vert ="${props.logo_mobile_vert}";
            Enabler.setDevDynamicContent(devDynamicContent);`
        );
        document.getElementById(props.title).contentWindow.addEventListener("load", function (e) {
            //document.getElementsByTagName('iframe')[4].contentWindow.document.body.appendChild(script);
            Array.from(document.getElementsByTagName('iframe')[props.iframenumber].contentWindow.document.head.querySelectorAll('script')).pop().append(script);
        })
    }
    const [contentRef, setContentRef] = useState(null)
    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        <iframe {...props} title={props.title} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}
