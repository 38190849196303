import { IFrame } from './IFrame';

export default function _160x600(props){

  return (
      <>
        <IFrame{...props} iframenumber={6}  width={"160"} height={"600"} title={"_160x600-Banner"} id={"_160x600-Banner"} src={'/Generic_Banners/generic_banner/Design1/Studio_Version/generic_responsive/index.html'}/>
      </>
  );
}
