import {FormContainer, Label, FormColumn, FormRow, CustomH1, DesignForm, FormItem, Note} from "../styles/styledMiscComponents";
import {Alert, Button, Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import axios from "axios";
import OmniDataService from "../../services/omniAxios";
import SelectColorTheme from './SelectColorTheme';

export default function FormFields(props) {
    const [massPreviewSheetRowNumbers, setMassPreviewSheetRowNumbers] = useState(0);
    const [showAlert, setShowAlert] = useState(null);
    const [showAlertError, setShowAlertError] = useState(null);
    const [showAlertSave, setShowAlertSave] = useState(null);
    const [showAlertSaveError, setShowAlertSaveError] = useState(null);
    const [rowNumber, setRowNumber] = useState(0);

    const handleSubmit = (e) => {
        axios.get('/getProperties', {
            params: {
                rowNumber : rowNumber,
                sheetTitle : props.sheetTitle
            }}).then(res => {
            props.setFormData(props.formatFormData(res.data))
            props.refreshAll();
            props.setBannerActive(true);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
        e.preventDefault();
    }

    const createMassPreviewSheets = async (e) => {
        if (massPreviewSheetRowNumbers.match(/\d+:\d+/) === null) {
            setShowAlert(false);
            setShowAlertError(true);
            return;
        }
        setShowAlertError(false);
        axios.get('/getPropertiesRange', {
            params: {
                rowNumbers: massPreviewSheetRowNumbers,
                sheetTitle: props.sheetTitle
            }
        }).then(res => {
            res.data.forEach((item, i) => {
                let obj = props.formatFormData(item)
                obj.name = obj.design + `_UniqueID${Number(massPreviewSheetRowNumbers.split(':')[0]) + i}_` + Math.floor(Math.random() * 1000);
                OmniDataService.createSheet(obj).then(res => {
                    console.log(res)
                })
                    .catch(e => {
                        console.log(e);
                        setShowAlertError(true)
                    });
            })
            setShowAlertError(false);
            setShowAlert(true);
        }).catch((error) => {
            setShowAlertError(true)
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
        e.preventDefault();
    }

    const saveToFeed = (e) => {
        axios.post('/saveProperties', {
                rowNumber : rowNumber,
                sheetTitle : props.sheetTitle,
                content : props.formData
            }).then(res => {
            setShowAlertSaveError(false);
            setShowAlertSave(true);
            }).catch((error) => {
            setShowAlertSave(false);
            setShowAlertSaveError(true)
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
        e.preventDefault();
    }

    const openBlankForm = (e) => {
        axios.get('/getFirstEmptyRowIndex', {
            params: {
                sheetTitle: props.sheetTitle
            }
        }).then(res => {
            setRowNumber(res.data.newLine)
            props.setFormData(props.formatFormData({sheet : res?.data?.sheet}))
            props.refreshAll();
            props.setBannerActive(true);
        }).catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
        e.preventDefault();
    }

return (
    <Stack sx={{my: 2}} direction="column" spacing={6}>
        <DesignForm>
            <FormItem>
                <TextField id="filled-basic" label="Unique ID" variant="filled"
                           onChange={(e) => setRowNumber(e.target.value)}/>
                <Button onClick={handleSubmit} variant="contained">Submit</Button>
            </FormItem>
            <FormItem>
                <TextField id={"filled-basic"} placeholder={"1:10"} label={"Create Mass Preview Sheets"} variant={"filled"}
                           onChange={(e) => setMassPreviewSheetRowNumbers(e.target.value)}/>
                <Button onClick={createMassPreviewSheets} variant={"contained"}>Save</Button>
                {showAlert && <Alert severity="success">Created!</Alert>}
                {showAlertError && <Alert severity="error">Invalid Entry</Alert>}
            </FormItem>
            {props.saving &&
                <FormItem>
                    <Button onClick={openBlankForm} variant="contained">Create New Line</Button>
                </FormItem>
            }
        </DesignForm>
        {props.bannerActive && <div>
            <FormContainer>
                <FormRow>
                    <CustomH1>Attributes</CustomH1>
                </FormRow>
                <FormRow>
                    <FormColumn>
                        {props.formData ? Object.entries(props.formData).slice(0, Math.floor(Object.entries(props.formData).length / 2)).map((property) => {
                            let label = property[0].replaceAll("_", " ").toLowerCase().replaceAll("url", "URL").replaceAll("cta", "CTA").replaceAll(" id", " ID");
                            let label_clean = property[0];

                            label = label.split(" ").map((word) => {
                                return word[0].toUpperCase() + word.substring(1);
                            }).join(" ");

                            let label_value = property[1];
                            if (label === "Name" || label === "Color Theme" || label === "Sheet" || label === "Design" || label === "Colortheme") {return null;}
                            return  (
                                <Label key={label_clean}>{label}
                                    <TextField sx={{marginLeft: "10px"}} id="standard-basic" key={label_clean + 'value'} variant="standard" type="text" value={label_value} onChange={(e) => props.setFormData({...props.formData, [label_clean]: e.target.value})} />
                                </Label>
                            )
                        }) : <div></div>}
                    </FormColumn>
                    <FormColumn>
                        {props.formData ? Object.entries(props.formData).slice(Math.floor(Object.entries(props.formData).length / 2)).map((property) => {
                            let label = property[0].replaceAll("_", " ").toLowerCase().replaceAll("url", "URL").replaceAll("cta", "CTA").replaceAll(" id", " ID");
                            let label_clean = property[0];

                            label = label.split(" ").map((word) => {
                                return word[0].toUpperCase() + word.substring(1);
                            }).join(" ");

                            let label_value = property[1];
                            if (label === "Name" || label === "Color Theme" || label === "Sheet" || label === "Design" || label === "Colortheme") {return null;}

                            return (
                                <Label key={label_clean}>{label}
                                    <TextField sx={{marginLeft: "10px"}} id="standard-basic" key={label_clean + 'value'} variant="standard" type="text" value={label_value} onChange={(e) => props.setFormData({...props.formData, [label_clean]: e.target.value})}/>
                                </Label>
                            )
                        }) : <div></div>}
                    </FormColumn>
                </FormRow>
                {props.formData.sheet &&
                <SelectColorTheme themeColumnName={props.themeColumnName} handleColorThemeSubmit={props.handleColorThemeSubmit} setFormData={props.setFormData} formData={props.formData}/>}
                {props.saving &&
                    <Note>Saving to Feed is a beta feature: you may experience errors. Please check feed sheet to verify and report any bugs<br/>{props.note}</Note>
                }
                {props.saving && <FormRow>
                    <Button onClick={saveToFeed} variant={"contained"}>Save To Feed</Button>
                    {showAlertSave && <Alert severity="success">Saved!</Alert>}
                    {showAlertSaveError && <Alert severity="error">Failed to Save</Alert>}
                </FormRow>
                }
            </FormContainer>
        </div>}
    </Stack>
    );
}
