import { IFrame } from './IFrame';

export default function _320x50(props) {

    return (
        <>
            <IFrame {...props} iframenumber={2} width={"320"} height={"50"} title={"_320x50-Banner"} id={"_320x50-Banner"} src={'/TheContainerStore/the-container-store/2022_Design/Studio_Version/TCS_Banner_Updates_Responsive/index.html'}/>

        </>
    );
}
