import  styled  from  'styled-components';
import { PackageHeadline1, MobilePackageHeadline2, MobilePackageButtonFade, MobilePackageShine } from '../animations/Omni_animations'

export const AdContainer = styled.div`
    height: 48px;
    width: 298px;
    background-color: white;
    overflow: hidden;
    position: relative;
    border: 1px solid grey;
`

export const LeftTopBar = styled.div`
    background-color: ${props => props.primaryColor};
    position: absolute;
    height: 27px;
    width: 4px;
    z-index: 2;
    left: 0px;
    top: -1px;
`

export const LeftBottomBar = styled.div`
    background-color: ${props => props.secondaryColor};   
    position: absolute;
    height: 27px;
    width: 4px;
    z-index: 2;
    left: 0px;
    top: 25px;
`

export const LogoContainer = styled.div`
    background-color: ${props => props.primaryColor};
    position: relative;
    height: 52px;
    width: 126px;
    left: 175px;
    top: -1px;
    vertical-align: middle;
    place-items: center;
    display: flex;
`

export const Logo = styled.img`
    width: 85%;
    margin-left: auto;
    margin-right: auto;
`

export const HeadlineContainer = styled.div`
    position: absolute;
    left: 3px;
    top: -2px;
    height: 50px;
    width: 173px;
`

export const HeadlineWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`

export const Headline1 = styled.p`
    margin: 0;
    padding: 5px 0px 0px 15px;
    font-family: "Arquitecta-Bold";
    opacity: 0;
    height: 50px;
    opacity: 0;
    letter-spacing: 1px;
    position: relative;
    top: 3px;
    font-size: 20px;
    line-height: 17px;
    color: ${props => props.primaryColor};
    animation: ${PackageHeadline1} 6s forwards;
`

export const Headline2 = styled.p`
    margin: 0;
    padding: 5px 0px 0px 15px;
    font-family: "Arquitecta-Bold";
    opacity: 0;
    height: 50px;
    letter-spacing: 1px;
    position: relative;
    font-size: 15px;
    line-height: .9;
    top: -55px;
    margin-right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.primaryColor};
    animation: ${MobilePackageHeadline2} 10s forwards;
`
export const ButtonContainer = styled.div`
    margin-top: 3px;
    position: relative;
    left: 4px;
    height: 50px;
    width: 170px;
    bottom: 43px;
`

export const Button = styled.div`
    height: 25px;
    width: 110px;
    background: none;
    font-size: 10px;
    left: 25px;
    font-family: "Arquitecta-Book";
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border: 1px solid ${props => props.primaryColor};
    color: ${props => props.primaryColor};
    animation: ${MobilePackageButtonFade} 20s forwards;
`

export const Shine = styled.div`
    position: relative;
    top: -26px;
    left: -20px;
    height: 20px;
    width: 20px;
    background: rgba(255, 255, 255, 0.4);
    animation: ${MobilePackageShine} 20s forwards;
`