import { IFrame } from './IFrame';

export default function _320x50(props) {

    return (
        <>
            <IFrame {...props} iframenumber={2} width={"320"} height={"50"} title={"_320x50-Banner"} id={"_320x50-Banner"} src={'/Generic_Banners/generic_banner/Design1/Studio_Version/generic_responsive/index.html'}/>
        </>
    );
}
