import  styled  from  'styled-components';
import { PackageButtonFade, PackageHeadline1, PackageHeadline2, PackageImageFade, PackageShine} from '../animations/Omni_animations'

export const AdContainer = styled.div`
    overflow: hidden;
    position: relative;
    height: 248px;
    width: 298px;
    background-color: white;
    border: 1px solid grey;
`

export const TopBar = styled.div`
    background-color: ${props => props.primaryColor};
    position: absolute;
    height: 7px;
    width: 300px;
    z-index: 2;
`

export const LogoContainer = styled.div`
    background-color: ${props => props.primaryColor};
    position: absolute;
    top: 7px;
    left: 75px;
    height: 50px;
    width: 150px;
    display: grid;
    place-items: center;
`

export const Logo = styled.img`
    width: 120px;
`

export const ImageContainer = styled.div`
    width: 300px;
    overflow: hidden;   
`

export const Image1 = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 300px;   
    height: 150px;
    opacity: 0;
    background-image: url(${props => props.image1Horizontal});
    animation: ${PackageImageFade} 10s forwards;
`

export const HeadlineContainer = styled.div`
    display: grid;
    place-items: center;
    height: 50px;
    width: 300px;
    margin-top: 5px;
`

export const Headline1 = styled.p`
    color: ${props => props.primaryColor};
    margin: 0;
    padding: 0;
    font-family: "Arquitecta-Bold";
    letter-spacing: 1px;
    text-align: center;
    opacity: 0;
    font-size: 25px;
    width: 175px;
    line-height: 23px;
    animation: ${PackageHeadline1} 6s forwards;
`

export const Headline2 = styled.p`
    color: ${props => props.primaryColor};
    margin: 0;
    padding: 0;
    font-family: "Arquitecta-Bold";
    text-align: center;
    position: relative;
    font-size: 20px;
    top: -40px;
    width: 275px;
    line-height: 20px;
    letter-spacing: .7px;
    animation: ${PackageHeadline2} 6s forwards;
`

export const ButtonContainer = styled.div`
    display: grid;
    place-items: center;
`

export const Button = styled.div`
    margin-top: 2px;
    height: 25px;
    width: 110px;
    background: none;
    font-size: 11px;
    font-family: "Arquitecta-Book";
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.primaryColor};
    color: ${props => props.primaryColor};
    animation: ${PackageButtonFade} 8s forwards;
`

export const Shine = styled.div`
    position: relative;
    top: -26px;
    left: -60px;
    height: 20px;
    width: 20px;
    background: rgba(255, 255, 255, 0.4);
    animation: ${PackageShine} 8s forwards;
`