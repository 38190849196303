import { IFrame } from './IFrame';

export default function _728x90(props){

    return (
        <>
            <IFrame {...props} iframenumber={1} width={"728"} height={"90"} title={"_728x90-Banner"} id={"_728x90-Banner"} src={'/Generic_Banners/generic_banner/Design1/Studio_Version/generic_responsive/index.html'}/>
        </>
    );
}
